import { makeRequest } from './RequestService';

export class APIClient {
  private static instance: APIClient;

  private constructor(private baseURL: string) { }

  public static getInstance(baseURL: string): APIClient {
    if (!APIClient.instance) {
      APIClient.instance = new APIClient(baseURL);
    }
    return APIClient.instance;
  }

  private async request<T>(url: string, options: RequestInit, authRequired: boolean): Promise<T> {
    return makeRequest<T>(this.baseURL, url, options, authRequired);
  }

  get<T>(url: string, customHeaders: Record<string, string> = {}, authRequired = true): Promise<T> {
    return this.request<T>(
      url,
      {
        method: 'GET',
        headers: customHeaders
      },
      authRequired
    );
  }

  post<T>(url: string, data: unknown, customHeaders: Record<string, string> = {}, authRequired = true): Promise<T> {
    return this.request<T>(
      url,
      {
        method: 'POST',
        headers: customHeaders,
        body: JSON.stringify(data)
      },
      authRequired
    );
  }

  put<T>(url: string, data: unknown, customHeaders: Record<string, string> = {}, authRequired = true): Promise<T> {
    return this.request<T>(
      url,
      {
        method: 'PUT',
        headers: customHeaders,
        body: JSON.stringify(data)
      },
      authRequired
    );
  }

  patch<T>(
    url: string,
    data: unknown,
    customHeaders: Record<string, string> = {},
    authRequired = true
  ): Promise<T> {
    return this.request<T>(
      url,
      {
        method: 'PATCH',
        headers: customHeaders,
        body: JSON.stringify(data)
      },
      authRequired
    );
  }

  delete<T>(
    url: string,
    data: unknown,
    customHeaders: Record<string, string> = {},
    authRequired = true
  ): Promise<T> {
    return this.request<T>(
      url,
      {
        method: 'DELETE',
        headers: customHeaders,
        body: JSON.stringify(data)
      },
      authRequired
    );
  }
}

export const apiClient = APIClient.getInstance('/api');

import React, { ReactNode } from 'react';

import { useMediaQuery } from '@mui/material';

import { BreadcrumbItem } from '../../models/BreadcrumbItem';
import BreadcrumbLayout from './BreadcrumbLayout';
import SingleColumnLayout from './SingleColumnLayout';
import TwoColumnLayout from './TwoColumnLayout';

interface ResponsiveLayoutProps {
  breadcrumbItems?: BreadcrumbItem[];
  children: ReactNode;
}

const ResponsiveLayout: React.FC<ResponsiveLayoutProps> = ({ children, breadcrumbItems = [] }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isBreadcrumbLayout = breadcrumbItems.length > 0;

  if (isSmallScreen) {
    return <SingleColumnLayout>{children}</SingleColumnLayout>;
  } if (isBreadcrumbLayout) {
    return <BreadcrumbLayout breadcrumbItems={breadcrumbItems}>{children}</BreadcrumbLayout>;
  } 
    return <TwoColumnLayout>{children}</TwoColumnLayout>;
  
};

export default ResponsiveLayout;

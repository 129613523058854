import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SessionService } from "../services/SessionService";
import sessionStore from "../stores/SessionStore";

const withSessionHydration = <P extends object>(WrappedComponent: React.ComponentType<P>): FC<P> => {
  const SessionHydratedComponent: FC<P> = (props) => {
    const [isSessionHydrated, setIsSessionHydrated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const hydrateSession = async () => {
        try {
          // Validate the session using the SessionService
          await SessionService.getCSRFToken();
          await SessionService.validateSession();
          sessionStore.setIsAuthenticated(true);
        } catch (error) {
          console.error('Error hydrating session:', error);
        } finally {
          setIsSessionHydrated(true);
        }
      };

      hydrateSession();
    }, []);

    if (!isSessionHydrated) {
      // Show a loading screen while the session is being hydrated TODO: Add a loading spinner
      return <div>Loading session...</div>;
    }

    // Render the wrapped component once the session is hydrated
    return <WrappedComponent {...props} />;
  };

  return SessionHydratedComponent;
};

export default withSessionHydration;

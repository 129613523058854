import { useTranslation } from 'react-i18next';
import { Box } from '@mui/joy';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { Campaign } from '../../types/Campaign';
import { formatTimestamp } from '../../general-utilities/time-util';
import { CampaignBadge } from './CampaignBadge';
import { CampaignStatus } from '../../models/CampaignStatus.enum';

interface CampaignCardProps {
  campaign: Campaign;
  startCampaign: (campaignId: string) => void;
  joinCampaign: (campaignId: string) => void;
  stopCampaign: (campaignId: string) => void;
}

export default function CampaignCard({ campaign, startCampaign, joinCampaign, stopCampaign }: CampaignCardProps) {
  const isActive = campaign.status === 1;
  const { t, i18n } = useTranslation();

  const getFooter = () => {
    if (isActive) {
      return (
        <div className="flex flex-row gap-4">
          <Button
            variant="outlined"
            size="md"
            color="neutral"
            className="font-semibold"
            onClick={() => stopCampaign(campaign.id)}
          >
            {t('campaigns.end')}
          </Button>
          <Button
            variant="solid"
            size="md"
            color="primary"
            className="font-semibold self-center !ml-auto"
            onClick={() => joinCampaign(campaign.id)}
          >
            {t('campaigns.join')}
          </Button>
        </div>
      );
    }
    return (
      <div className="flex">
        <Button
          variant="solid"
          size="md"
          color="primary"
          aria-label="Start Campaign"
          className="font-semibold h-0 !ml-auto"
          onClick={() => startCampaign(campaign.id)}
        >
          {t('campaigns.start')}
        </Button>
      </div>
    );
  };
  const getCampaignStatus = () => {
    if (campaign.end_time) {
      return CampaignStatus.ended;
    }
    if (campaign.start_time) {
      return CampaignStatus.ongoing;
    }
    if (campaign.start_time === null) {
      return CampaignStatus.neverStarted;
    }
    return CampaignStatus.unknown;
  };

  const getStartEndTimeSection = () => {
    if (!campaign.start_time) {
      return null; // No start time, return nothing
    }

    const endTimeText = campaign.end_time ? formatTimestamp(campaign.end_time, i18n.language) : 'Ongoing';

    return (
      <Box
        className="flex flex-col gap-4 p-4 border rounded-lg mt-4"
        sx={{
          backgroundColor: 'background.level1',
          borderColor: 'divider',
        }}
      >
        {/* Start Time */}
        <Box className="w-full">
          <Typography className="text-lg font-medium" level="body-md">
            {t('campaigns.start_label')}
          </Typography>
          <Typography className="whitespace-nowrap overflow-hidden text-ellipsis text-md" level="body-md">
            {formatTimestamp(campaign.start_time, i18n.language)}
          </Typography>
        </Box>

        {/* End Time */}
        <Box className="w-full">
          <Typography className="text-lg font-medium" level="body-md">
            {t('campaigns.end_label')}
          </Typography>
          <Typography className="whitespace-nowrap overflow-hidden text-ellipsis text-md" level="body-md">
            {endTimeText}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Card
      className="w-80 flex flex-col justify-between h-full border  rounded-lg shadow-md"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        className="flex-1 flex flex-col justify-start gap-4 p-2"
        sx={{
          padding: 0.5,
        }}
      >
        <Box className="flex justify-between">
          <Typography className="text-xl font-bold" level="title-lg">
            {campaign.name}
          </Typography>
          <CampaignBadge status={getCampaignStatus()} />
        </Box>
        <Typography className=" mb-2" level="body-md" sx={{ color: 'text.secondary' }}>
          {campaign.description}
        </Typography>
        {getStartEndTimeSection()}
      </Box>

      <CardContent
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {getFooter()}
      </CardContent>
    </Card>
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import mapboxgl from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
import arrowIcon from '../icons/arrow.svg';

mapboxgl.accessToken = 'pk.eyJ1IjoianVsaWFub2VzIiwiYSI6ImNrOGNwbXVzbTA2bnMzZW95bmhvemIwbGkifQ.vHzrkhopow_IrtZm5yP5iA';

const toRad = (deg: number) => (deg * Math.PI) / 180;

function getMidpoint(a: [number, number], b: [number, number]): [number, number] {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
}

function getBearing(start: [number, number], end: [number, number]): number {
  const [lng1, lat1] = start;
  const [lng2, lat2] = end;
  
  const rLat1 = toRad(lat1);
  const rLat2 = toRad(lat2);
  const dLng = toRad(lng2 - lng1);

  const y = Math.sin(dLng) * Math.cos(rLat2);
  const x = Math.cos(rLat1) * Math.sin(rLat2) - Math.sin(rLat1) * Math.cos(rLat2) * Math.cos(dLng);
  
  let brng = Math.atan2(y, x);
  brng = (brng * 180) / Math.PI;
  return (brng + 360) % 360;
}

const MapCardView = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markerRef = useRef<mapboxgl.Marker | null>(null);
  const [prevPosition, setPrevPosition] = useState<[number, number] | null>(null);
  const [currentPosition, setCurrentPosition] = useState<[number, number] | null>(null);
  const [firstDataReceived, setFirstDataReceived] = useState(false);

  const initializeWebSocket = useCallback(() => {
    const protocol = process.env.REACT_APP_SERVER_WEBSOCKET_PROTOCOL || 'wss';
    const hostname = process.env.REACT_APP_SERVER_HOSTNAME || window.location.hostname;
    const socket = new WebSocket(`${protocol}://${hostname}/ws/telemetry`);

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data?.position) {
          const newLng = data.position.longitude_deg;
          const newLat = data.position.latitude_deg;
      
          if (!Number.isNaN(newLng) && !Number.isNaN(newLat)) {
            setPrevPosition(currentPosition);
            setCurrentPosition([newLng, newLat]);
      
            if (!firstDataReceived) {
              setFirstDataReceived(true);
            }
          } else {
            console.error("Received invalid coordinates:");
          }
        }
      } catch (err) {
        console.error("Error parsing WebSocket data:", err);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket connection error:", error);
    };

    return () => {
      socket.close();
    };
  }, [currentPosition, firstDataReceived]);
  

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-v9',
        center: [-122.4384, 37.7890], // Initial center on Map
        zoom: 18,
        attributionControl: false
      });

      map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
      map.current.addControl(new mapboxgl.ScaleControl(), "top-left");

      map.current.on('load', () => {
        if (!map.current) return;

        map.current.addSource('path', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: []
            }
          }
        });

        map.current.addLayer({
          id: 'path-line',
          type: 'line',
          source: 'path',
          paint: {
            'line-color': '#FFD700',
            'line-width': 5
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    const sckt = initializeWebSocket();
    return sckt;
  }, [initializeWebSocket]);

  useEffect(() => {
    if (!map.current || !currentPosition) return;

    if (!markerRef.current && firstDataReceived) {
      // Create marker only when the first data is received
      const arrowElement = document.createElement('div');
      arrowElement.style.width = '30px';
      arrowElement.style.height = '30px';
      arrowElement.style.backgroundImage = `url(${arrowIcon})`;
      arrowElement.style.backgroundSize = 'cover';

      markerRef.current = new mapboxgl.Marker({ element: arrowElement })
        .setLngLat(currentPosition)
        .addTo(map.current);
    }

    if (markerRef.current && prevPosition) {
      const midpoint = getMidpoint(prevPosition, currentPosition);
      const bearing = getBearing(prevPosition, currentPosition);

      markerRef.current.setLngLat(midpoint);
      markerRef.current.getElement().style.transform = `rotate(${bearing}deg)`;

      map.current.flyTo({
        center: currentPosition,
        essential: true,
        speed: 0.5,
        curve: 1.2
      });

      const source = map.current.getSource('path') as mapboxgl.GeoJSONSource;
      if (source) {
        source.setData({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [prevPosition, currentPosition]
          }
        });
      }

      map.current.setCenter(midpoint);
    }
  }, [prevPosition, currentPosition, firstDataReceived]);

  return (
    <div className="w-full h-full flex relative">
      <style>
        {`
          .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib {
            display: none !important;
          }
        `}
      </style>
  
      <div className="w-full h-full" ref={mapContainer} />
    </div>
  );
};

export default React.memo(MapCardView);

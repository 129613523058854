export default function getCSRFToken() {
  const name = 'csrftoken'; // Django's default CSRF token cookie name
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name  }=`)) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, FormControl, Typography } from '@mui/joy';

import { SelectField } from '../../components/SelectField';
import { UserProfileCard } from '../../components/UserProfileCard';
import { themeOptions } from '../../constants/ThemeOptions';
import { Mode, useCustomTheme } from '../../contexts/ThemeContext';
import { UserService } from '../../services/UserService';
import sessionStore from '../../stores/SessionStore';

interface ThemePreferenceCardProps {
  theme: string;
  setTheme: (newTheme: string) => void;
}

export const ThemePreferenceCard: React.FC<ThemePreferenceCardProps> = ({ theme, setTheme }) => {
  const { t } = useTranslation();
  const { changeTheme } = useCustomTheme();

  const {user} = sessionStore;
  const { control } = useForm({
    defaultValues: {
      theme
    }
  });

  const handleThemeChange = async (newValue: string) => {
    if (user?.id) {
      try {
        await UserService.updateUser(user.id, { color_scheme_preference: newValue });
        sessionStore.user = { ...user, color_scheme_preference: newValue };
        sessionStore.saveSession();
        changeTheme(newValue as Mode);
      } catch (error) {
        console.error('Error updating user theme preference:', error);
      }
    }
  };

  return (
    <UserProfileCard>
      <FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography level="h3">{t('edit_profile.theme')}</Typography>

          <Controller
            control={control}
            name="theme"
            render={({ field }) => (
              <SelectField
                label=""
                name={field.name}
                onChange={(value) => {
                  field.onChange(value);
                  handleThemeChange(value.toString());
                }}
                options={themeOptions}
                sx={{ width: '220px' }}
                value={field.value}
              />
            )}
          />
        </Box>
      </FormControl>
    </UserProfileCard>
  );
};

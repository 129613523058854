/* eslint-disable no-console */
import { createContext, useContext, useState, FC, useCallback } from 'react';

import { gstWebRTCAPI } from '../third-party-lib/gstwebrtc-api/gstwebrtc-api';

interface WebRTCContextType {
  isConnected: boolean;
  latestProducer: Producer | null;
  allProducers: Producer[];
  isSessionStarted: boolean;
  setLatestProducer: (producer: Producer | null) => void;
  setAllProducers: (producers: Producer[]) => void;
  setIsConnected: (isConnected: boolean) => void;
  startConsumerSession: (producerId: string) => void;
  stopConsumerSession: () => void;
  getStream: (producerId: string) => Promise<MediaStream>;
}

export interface Producer {
  id: string;
  stream?: MediaStream;
  meta?: object;
}

interface WebRTCProviderProps {
  children: React.ReactNode;
}

const WebRTCContext = createContext<WebRTCContextType | undefined>(undefined);
export const WebRTCProvider: FC<WebRTCProviderProps> = ({ children }: WebRTCProviderProps) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [latestProducer, setLatestProducer] = useState<Producer | null>(null);
  const [allProducers, setAllProducers] = useState<Producer[]>([]);
  const [consumerSession, setConsumerSession] = useState<any>(null); // eslint-disable-line @typescript-eslint/no-explicit-any
  const [isSessionStarted, setIsSessionStarted] = useState<boolean>(false);

  const stopConsumerSession = (): void => {
    if (consumerSession) {
      consumerSession.close();
      setConsumerSession(null);
    }
  };

  const startConsumerSession = useCallback(
    (producerId: string) => new Promise(resolve => {
      if (consumerSession) {
        consumerSession.close();
        setConsumerSession(null);
      }

      const session = gstWebRTCAPI.createConsumerSession(producerId);

      const onStreamsChanged = (): void => {
        const { streams } = session;
        console.log('producer after stream changed', allProducers);
        setIsSessionStarted(true);
        resolve(streams[0] as MediaStream); // Resolve the promise with the streams
        session.removeEventListener('streamsChanged', onStreamsChanged); // Clean up the event listener
      };

      session.addEventListener('streamsChanged', onStreamsChanged);
      session.addEventListener('error', () => {
        console.log('session error');
      });
      session.addEventListener('closed', () => {
        console.log('session closed');
      });
      session.connect();
      setConsumerSession(session);
    }),
    [consumerSession, setConsumerSession, allProducers, setIsSessionStarted]
  );

  const getStream = async (producerId: string): Promise<MediaStream> => {
    try {
      const stream = (await startConsumerSession(producerId)) as MediaStream;
      return stream;
    } catch (error) {
      console.error('Error starting consumer session', error);
      throw error;
    }
  };

  const value: WebRTCContextType = {
    isConnected,
    latestProducer,
    allProducers,
    isSessionStarted,
    setLatestProducer,
    setAllProducers,
    setIsConnected,
    startConsumerSession,
    stopConsumerSession,
    getStream
  };

  return <WebRTCContext.Provider value={value}>{children}</WebRTCContext.Provider>;
};

export const useWebRTC = (): WebRTCContextType => {
  const context = useContext(WebRTCContext);
  if (context === undefined) {
    throw new Error('useWebRTC must be used within a WebRTCProvider');
  }
  return context;
};

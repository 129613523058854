/* eslint-disable react/jsx-sort-props */
import { useState } from 'react';

import { Box } from '@mui/joy';

import { WebRTCProvider } from '../../contexts/WebRtcContext';
import ProducerManager from '../ProducerManager';
import CampaignActivityFeed from './CampaignActivityFeed';
import CampaignHeader, { BigPictureDisplayType } from './CampaignHeader';
import ViewDetails from './ViewDetails';
import Viewport from './Viewport';

type CampaignViewProps = {
  projectName: string,
  campaignName: string
}

const CampaignView: React.FC<CampaignViewProps> = ({
  projectName,
  campaignName
}) => {
  const [bigPictureDisplayType, setBigPictureDisplayType] = useState<BigPictureDisplayType>(BigPictureDisplayType.LIVE_ACTIVITY);
  const [viewSidebar, setViewSidebar] = useState<boolean>(false);
  const [pictureInPicture, setPictureInPicture] = useState<boolean>(false);
  const [fileMapStack, setFileMapStack] = useState<boolean>(false);

  return (
    <WebRTCProvider>
      <ProducerManager />
      <Box className='flex flex-col w-full h-full max-h-full'>
        <CampaignHeader
          projectName={projectName}
          campaignName={campaignName}
          bigPictureDisplayType={bigPictureDisplayType}
          setBigPictureDisplayType={setBigPictureDisplayType}
        />
        <Box className='flex flex-row w-full h-[calc(100%-48px)] max-h-[calc(100%-48px)]'>
          <CampaignActivityFeed />
          <Box className='flex flex-col w-full max-h-full flex-1'>
            <Viewport
              displayPictureInPicture={pictureInPicture}
              bigPictureDisplayType={bigPictureDisplayType}
            />
            <ViewDetails
              viewSidebar={viewSidebar}
              pictureInPicture={pictureInPicture}
              fileMapStack={fileMapStack}
              setViewSidebar={setViewSidebar}
              setPictureInPicture={setPictureInPicture}
              setFileMapStack={setFileMapStack}
            />
          </Box>
        </Box>
      </Box>
    </WebRTCProvider>
  );
}

export default CampaignView;
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography, useTheme } from '@mui/joy';

import { TextInputField } from '../../components/TextInputField';
import resetPasswordEmailSchema from '../../schema/resetPasswordEmailSchema';
import { UserService } from '../../services/UserService';

interface ResetPasswordForm {
  email: string;
}

export const EmailResetForm = ({ setEmailSent, setSubmittedEmail }: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ResetPasswordForm>({
    defaultValues: { email: '' },
    resolver: yupResolver(resetPasswordEmailSchema(t))
  });

  const onEmailSubmit: SubmitHandler<ResetPasswordForm> = async (data) => {
    try {
      await UserService.sendResetPasswordEmail(data.email);
      toast.success(t('reset_password.success.email_sent'));
      setEmailSent(true);
      setSubmittedEmail(data.email);
    } catch (error) {
      toast.error(t('reset_password.errors.email_failed'));
    }
  };

  return (
    <form onSubmit={handleSubmit(onEmailSubmit)}>
      <Box>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextInputField
              label={t('edit_profile.email')}
              {...field}
              error={errors.email ? errors.email.message : ''}
              sx={{
                width: '100%',
                paddingX: '16px',
                paddingY: '12px',
                minHeight: '48px',
                fontSize: '14px',
                borderWidth: '1px',
                borderRadius: '6px'
              }}
            />
          )}
        />
      </Box>
      <Typography sx={{ color: theme.palette.neutral[600], marginBottom: '2rem' }}>
        {t('reset_password.reset_link')}
      </Typography>
      <Button
        color="neutral"
        sx={{
          width: '100%',
          borderRadius: '4px',
          paddingY: '8px',
          paddingX: '24px',
          marginTop: '16px',
          fontSize: '1rem',
          lineHeight: '1rem',
          fontWeight: '400',
          minHeight: '3rem'
        }}
        type="submit"
      >
        {t('reset_password.button')}
      </Button>
    </form>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Button, Typography, useTheme } from '@mui/joy';
import * as Yup from 'yup';

import getAccountFormSchema from '../schema/getAccountFormSchema';
import { CreateAccountFormFields } from '../types/CreateAccountFormFields';
import VideoLayout from './layout/VideoLayout';
import { TextInputField } from './TextInputField';

interface CreateAccountFormProps {
  disableEmail: boolean;
  buttonText: string;
  formData: CreateAccountFormFields;
  setFormData: (formData: CreateAccountFormFields) => void;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string>) => void;
  onSubmit: () => void;
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = ({
  disableEmail,
  buttonText,
  formData,
  setFormData,
  errors,
  setErrors,
  onSubmit,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const validateForm = async (): Promise<boolean> => {
    const schema = getAccountFormSchema(t);
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const formErrors: Record<string, string> = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = error.message;
          }
        });
        setErrors(formErrors);
      }
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      onSubmit();
    }
  };

  return (
    <VideoLayout>
      <Box sx={{ maxWidth: '28rem' }}>
        <Typography
          level='h2'
          sx={{
            fontSize: '2.5rem',
            lineHeight: '3rem',
            fontWeight: 600,
            marginBottom: '1.5rem',
            fontFamily: theme.fontFamily.display,
          }}
        >
          {t('signup_page.create_sifly_account')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextInputField
            disabled={disableEmail}
            error={errors.email}
            label={t('signup_page.email_address')}
            name='email'
            onChange={handleChange}
            placeholder={t('signup_page.email_address')}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            value={formData.email}
          />
          <Box sx={{ display: { lg: 'flex' }, justifyContent: { lg: 'space-between' } }}>
            <TextInputField
              error={errors.firstName}
              label={t('signup_page.first_name')}
              name='firstName'
              onChange={handleChange}
              placeholder={t('signup_page.first_name')}
              sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
              value={formData.firstName}
            />
            <TextInputField
              error={errors.lastName}
              label={t('signup_page.last_name')}
              name='lastName'
              onChange={handleChange}
              placeholder={t('signup_page.last_name')}
              sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
              value={formData.lastName}
            />
          </Box>
          <TextInputField
            error={errors.callSign}
            label={t('signup_page.call_sign')}
            name='callSign'
            onChange={handleChange}
            placeholder={t('signup_page.call_sign')}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            value={formData.callSign}
          />
          <TextInputField
            error={errors.password}
            label={t('signup_page.password')}
            name='password'
            onChange={handleChange}
            placeholder={t('signup_page.password')}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            type='password'
            value={formData.password}
          />
          <TextInputField
            error={errors.confirmPassword}
            label={t('signup_page.confirm_password')}
            name='confirmPassword'
            onChange={handleChange}
            placeholder={t('signup_page.confirm_password')}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            type='password'
            value={formData.confirmPassword}
          />
          <Button
            color='neutral'
            sx={{
              width: '100%',
              paddingY: '0.75rem',
              paddingX: '1.5rem',
              marginTop: '1rem',
              fontWeight: 400,
              height: '3rem',
            }}
            type='submit'
          >
            {buttonText}
          </Button>
        </form>

        <Box sx={{ marginTop: '1rem', textAlign: 'center' }}>
          <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 400, fontSize: '1rem' }}>
            <Typography style={{ marginRight: '0.5rem' }}>{t('signup_page.already_have_account')}</Typography>
            <Link className='underline' to='/'>
              {t('login_page.sign_in')}
            </Link>
          </Typography>
        </Box>
      </Box>
    </VideoLayout>
  );
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, Option, Select } from '@mui/joy';

import { Organization } from '../models/Company';
import User from '../models/User';
import { CompanyService } from '../services/CompanyService';
import { InvitationService } from '../services/InvitationService';
import { UserService } from '../services/UserService';

const SendOrgInvitation: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedObject, setSelectedObject] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersData = await UserService.getUsers();
        const organizationsData = await CompanyService.getOrganizations();
        setUsers(usersData);
        setOrganizations(organizationsData);
      } catch (err) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedUser === null || selectedObject === null) {
      setError(t('send_org_invitation.errors.select'));
      return;
    }
    try {
      await InvitationService.sendOrgInvitation(parseInt(selectedUser, 10), 'organization', parseInt(selectedObject, 10));
      alert(t('send_org_invitation.successful'));
      setSelectedUser(null);
      setSelectedObject(null);
    } catch (err) {
      toast.error(t('send_org_invitation.errors.invitation'));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 bg-white rounded-lg shadow-md max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">{t('send_org_invitation.send_invite')}</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form className="flex flex-col items-center" onSubmit={handleSubmit}>
          <Select
            className="mb-4 w-full"
            onChange={(event, value) => setSelectedUser(value as string)}
            placeholder={t('send_org_invitation.select_user')}
            value={selectedUser ?? ''}
          >
            {users.map((user) => (
              <Option key={user.id} value={String(user.id)}>
                {user.email}
              </Option>
            ))}
          </Select>

          <Select
            className="mb-4 w-full"
            onChange={(event, value) => setSelectedObject(value as string)}
            placeholder={t('send_org_invitation.select_org')}
            value={selectedObject ?? ''}
          >
            {organizations.map((org) => (
              <Option key={org.id} value={String(org.id)}>
                {org.name}
              </Option>
            ))}
          </Select>

          <Button
            className="mt-4"
            color="primary"
            disabled={selectedUser === null || selectedObject === null}
            type="submit"
            variant="solid"
          >
            {t('send_org_invitation.send_invite')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SendOrgInvitation;

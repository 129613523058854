import { trimStrings } from '../../general-utilities/string-utils';
import {
  DroneProfile,
  DroneProfileBackend,
  DroneProfileCreate,
  FullDroneProfileInput
} from '../../models/DroneProfile/DroneProfile.model';

export const serializeDroneProfileFromBackend = (backend: DroneProfileBackend): DroneProfile => ({
    id: backend.id,
    name: backend.name,
    eid: backend.eid,
    drone_model: backend.drone_model,
    drone_model_name: backend.drone_model_name,
    createdAt: new Date(backend.created_at),
    updatedAt: new Date(backend.updated_at)
  });

export const serializeDroneProfileCreateToBackend = (input: FullDroneProfileInput): DroneProfileCreate => {
  const trimmedInput = trimStrings(input);

  return {
    name: trimmedInput.name,
    eid: trimmedInput.eid,
    drone_model: trimmedInput.drone_model
  };
};

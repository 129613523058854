import { FC, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import './App.css';

import '@fontsource/barlow/100.css';
import '@fontsource/barlow/200.css';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/400.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow/800.css';
import '@fontsource/barlow/900.css';

import '@fontsource/be-vietnam-pro/100.css';
import '@fontsource/be-vietnam-pro/200.css';
import '@fontsource/be-vietnam-pro/300.css';
import '@fontsource/be-vietnam-pro/400.css';
import '@fontsource/be-vietnam-pro/500.css';
import '@fontsource/be-vietnam-pro/600.css';
import '@fontsource/be-vietnam-pro/700.css';
import '@fontsource/be-vietnam-pro/800.css';
import '@fontsource/be-vietnam-pro/900.css';
import { useLocation } from 'react-router-dom';

import { CssBaseline } from '@mui/joy';

import ThemedToastContainer from './components/ThemedToastContainer';
import ThemeManager from './components/ThemeManager';
import { SelectedOrgProvider } from './contexts/SelectedOrgContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { checkAndSetLanguage } from './general-utilities/languageUtils';
import withSessionHydration from './hoc/withSessionHydration';
import { i18n, i18nPromise } from './i18n';
import AppRoutes from './routes/routes';

const App: React.FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    i18nPromise.then(() => {
      setIsInitialized(true);
    });

    checkAndSetLanguage();

    const handleStorageChange = (): void => {
      checkAndSetLanguage();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    checkAndSetLanguage();
  }, [location]);

  if (!isInitialized) {
    // This is a temporary loading screen. Discuss with Deyge to come up with a full page loading component. This should not go to production.
    return <div>Loading translations...</div>;
  }

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemedToastContainer />
        <CssBaseline />
        <AppRoutes />
      </I18nextProvider>
    </>
  );
}

const HydratedApp = withSessionHydration(App);

const ThemeWrappedApp: FC = () => (
  <ThemeProvider>
    <SelectedOrgProvider>
      <ThemeManager />
      <HydratedApp />
    </SelectedOrgProvider>
  </ThemeProvider>
);

export default ThemeWrappedApp;

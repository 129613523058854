import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Button, CircularProgress, Container, Typography } from '@mui/joy';

import DroneModelConfigEdit from '../../components/DroneModels/configuration-edit/DroneModelConfigEdit';
import DroneModelDetailsEdit from '../../components/DroneModels/details-edit/DroneModelDetailsEdit';
import DroneModelConfigurationView from '../../components/DroneModels/DroneModelConfigurationView';
import DroneModelPropertiesView from '../../components/DroneModels/DroneModelPropertiesView';
import BreadcrumbLayout from '../../components/layout/BreadcrumbLayout';
import ResponsiveLayout from '../../components/layout/ResponsiveLayout';
import EditableCard from '../../components/SharedUI/Card/EditableCard';
import { useApiService } from '../../hooks/useAPIService';
import { BreadcrumbItem } from '../../models/BreadcrumbItem';
import { DroneModel } from '../../models/DroneModel/DroneModel.model';
import { DroneModelService } from '../../services/DroneModelService';

const DroneDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [drone, setDrone] = useState<DroneModel | null>(location.state?.drone || null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isConfigEditing, setIsConfigEditing] = useState<boolean>(false);
  const [isDetailsEditing, setIsDetailsEditing] = useState<boolean>(false);
  const { loading, callService } = useApiService(t);

  const [breadcrumbItems] = useState<BreadcrumbItem[]>([
    { text: t('drone_models.title'), link: '/drone-models' },
    { text: location.state?.drone.name || '' }
  ]);

  useEffect(() => {
    if (!drone && id) {
      const fetchDrone = async () => {
        try {
          const response = await callService({
            serviceFunction: DroneModelService.getDroneModelById,
            args: id
          });
          if (response.success && response.data) {
            if (response.data) {
              setDrone(response.data);
            } else {
              setDrone(null);
              toast.error(t('drone_models.errors.fetch_drone_model'));
            }
          } else {
            setDrone(null);
            toast.error(t('drone_models.errors.fetch_drone_model'));
          }
        } catch (error) {
          console.error('Error fetching drone details:', error);
        }
      };
      fetchDrone();
    }
  }, [drone, id, t, callService]);

  const saveSuccess = (updatedDrone: DroneModel) => {
    setDrone(updatedDrone);
  };

  const deleteDroneModel = async (droneId: string) => {
    try {
      const response = await callService({
        serviceFunction: DroneModelService.deleteDroneModel,
        args: droneId
      });

      if (response.success) {
        toast.success(t('drone_models.messages.drone_model_deleted'));
        setDrone(null);
        navigate('/drone-models');
      } else {
        toast.error(t('drone_models.errors.delete_drone_model'));
      }
    } catch (error) {
      console.error('Error deleting drone model:', error);
      toast.error(t('drone_models.errors.delete_drone_model'));
    }
  };
  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!drone) {
    return (
      <ResponsiveLayout breadcrumbItems={breadcrumbItems}>
        <Container>
          <Typography color="danger" level="h4">
            {t('drone_models.errors.drone_not_found')}
          </Typography>
        </Container>
      </ResponsiveLayout>
    );
  }

  return (
    <ResponsiveLayout>
      <BreadcrumbLayout
        breadcrumbItems={breadcrumbItems}
        controls={
          <Button
            color="warning"
            onClick={() => {
              deleteDroneModel(drone.id);
            }}
            variant="soft"
          >
            {t('drone_models.buttons.delete_drone_model')}
          </Button>
        }
      >
        <Container>
          {/* Box for handling layout */}
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ flex: 1, minWidth: '48%', minHeight: '300px' }}>
              <EditableCard
                editComponent={
                  <DroneModelDetailsEdit
                    initialData={drone}
                    onCancel={() => {
                      setIsDetailsEditing(false);
                    }}
                    onSuccessfulSave={(savedDrone: DroneModel) => {
                      setIsDetailsEditing(false);
                      saveSuccess(savedDrone);
                    }}
                  />
                }
                isEditing={isDetailsEditing}
                setEdit={setIsDetailsEditing}
                title="Drone Model Details"
                viewComponent={<DroneModelPropertiesView drone={drone} />}
              />
            </Box>

            <Box sx={{ flex: 1, minWidth: '48%', minHeight: '300px' }}>
              <EditableCard
                editComponent={
                  <DroneModelConfigEdit
                    drone={drone}
                    onCancel={() => {
                      setIsConfigEditing(false);
                    }}
                    onSuccessfulSave={(savedDroneConfig: DroneModel) => {
                      setIsConfigEditing(false);
                      saveSuccess(savedDroneConfig);
                    }}
                  />
                }
                isEditing={isConfigEditing}
                setEdit={setIsConfigEditing}
                title="Drone Config"
                viewComponent={<DroneModelConfigurationView drone={drone} />}
              />
            </Box>
          </Box>
        </Container>
      </BreadcrumbLayout>
    </ResponsiveLayout>
  );
};

export default DroneDetailsPage;

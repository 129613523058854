import { redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import getCSRFToken from '../general-utilities/getCsrfToken';
import {
  DroneError,
  DroneException,
  ErrorResponse,
  ForbiddenException,
  InvalidSessionException,
  ServerException,
  ValidationException
} from './Exceptions';

export async function makeRequest<T>(
  baseURL: string,
  url: string,
  options: RequestInit,
  authRequired: boolean
): Promise<T> {
  const csrfToken = getCSRFToken();

  const fetchOptions: RequestInit = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(csrfToken ? { 'X-CSRFToken': csrfToken } : {}),
      ...(options.headers || {})
    }
  };

  if (authRequired) {
    fetchOptions.credentials = 'include';
  }

  try {
    const response = await fetch(`${baseURL}${url}`, fetchOptions);
    if (!response.ok) {
      const errorResponse: ErrorResponse = await response.json();

      switch (response.status) {
        case 401:
          throw InvalidSessionException.fromErrorResponse(errorResponse);
        case 403:
          // TODO: discuss how to handle forbidden; maybe redirect to forbidden page.
          throw ForbiddenException.fromErrorResponse(errorResponse);
        case 422:
        case 400:
          // validation error
          throw ValidationException.fromErrorResponse(errorResponse);
        case 404:
          toast.error('Not Found');
          break;
        case 500:
        case 408:
          throw ServerException.fromErrorResponse(errorResponse);
        case 424:
          throw DroneException.fromErrorResponse(errorResponse as DroneError);
        default:
          throw new Error('Unexpected error');
      }
    }
    if (response.status === 204) {
      return {} as T; // No content
    }

    return await response.json();
  } catch (error: any) {

    if (error instanceof InvalidSessionException) {
      redirect('/');
    }
    if (error instanceof ForbiddenException) {
      redirect('/forbidden');
    }
    throw error;
  }
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography } from '@mui/joy';

import DronePropertiesCard from '../components/DronePropertiesCard';
import { SimpleHeaderLayout } from '../components/layout/SimpleHeaderLayout';
import { DroneData } from '../models/DroneProfile/DroneProfile.model';
import { DroneProfileService } from '../services/DroneProfileService';

export default function FactoryResetDrone() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [droneData, setDroneData] = useState<DroneData | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchDroneData = async () => {
      try {
        if (!id) {
          setError(true);
          return;
        }

        const result = await DroneProfileService.getDroneData(id);
        if (result.success) {
          setDroneData(result.data);
        }
      } catch {
        setError(true);
      }
    };

    fetchDroneData();
  }, [id]);

  const cancelButton = (
    <Typography onClick={() => navigate('/')} sx={{ cursor: 'pointer', alignSelf: 'center', margin: '1rem' }}>
      {t('edit_profile.cancel')}
    </Typography>
  );

  return (
    <SimpleHeaderLayout controlComponent={cancelButton}>
      <Box className="flex flex-col sm:flex-row min-h-[90vh]">
        <Box className="flex items-center justify-end sm:w-1/2 p-4">
          <Box className="max-w-[28rem]">
            <Typography level="h1" sx={{ marginBottom: '1rem', fontSize: '2.25rem', fontWeight: '600' }}>
              {t('register_drone.instructions.factory_reset')}
            </Typography>

            <Typography
              level="h4"
              sx={{ color: 'neutral.600', fontWeight: '600', fontSize: '1.25rem', marginY: '1rem' }}
            >
              {t('register_drone.instructions.factory_reset_title')}
            </Typography>

            <Box sx={{ display: 'flex', marginBottom: '0.25rem' }}>
              <Typography
                sx={{
                  backgroundColor: 'neutral.100',
                  color: 'neutral.700',
                  height: '32px',
                  width: '32px',
                  marginRight: '0.5rem',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                1
              </Typography>
              <Typography sx={{ marginBottom: '0.5rem', fontWeight: '500', fontSize: '1.125rem', width: '100%' }}>
                {t('register_drone.instructions.place_drone_somewhere')}
                <span style={{ textDecoration: 'underline' }}>
                  {t('register_drone.instructions.strong_cellular_connection')}
                </span>
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginBottom: '0.25rem' }}>
              <Typography
                sx={{
                  backgroundColor: 'neutral.100',
                  color: 'neutral.700',
                  height: '32px',
                  width: '32px',
                  marginRight: '0.5rem',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                2
              </Typography>
              <Typography sx={{ marginBottom: '0.5rem', fontWeight: '500', fontSize: '1.125rem', width: '100%' }}>
                {t('register_drone.instructions.plug_drone')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginBottom: '0.25rem' }}>
              <Typography
                sx={{
                  backgroundColor: 'neutral.100',
                  color: 'neutral.700',
                  height: '32px',
                  width: '32px',
                  marginRight: '0.5rem',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                3
              </Typography>
              <Typography sx={{ marginBottom: '0.5rem', fontWeight: '500', fontSize: '1.125rem', width: '100%' }}>
                {t('register_drone.instructions.turn_on_drone')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginBottom: '0.25rem' }}>
              <Typography
                sx={{
                  backgroundColor: 'neutral.100',
                  color: 'neutral.700',
                  height: '32px',
                  width: '32px',
                  marginRight: '0.5rem',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                4
              </Typography>
              <Typography sx={{ marginBottom: '0.5rem', fontWeight: '500', fontSize: '1.125rem', width: '100%' }}>
                {t('register_drone.instructions.press_button_until_light_flashes')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', marginBottom: '0.25rem' }}>
              <Typography
                sx={{
                  backgroundColor: 'neutral.100',
                  color: 'neutral.700',
                  height: '32px',
                  width: '32px',
                  marginRight: '0.5rem',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                5
              </Typography>
              <Typography sx={{ marginBottom: '0.5rem', fontWeight: '500', fontSize: '1.125rem', width: '100%' }}>
                {t('register_drone.instructions.press_button_to_reset')}
              </Typography>
            </Box>

            <Typography sx={{ marginBottom: '0.5rem', fontWeight: '500', fontSize: '1.125rem' }}>
              {t('register_drone.instructions.contact')}
              <a href="mailto:support@sifly.ai" style={{ textDecoration: 'underline' }}>
                support@sifly.ai
              </a>{' '}
              {t('register_drone.instructions.for_additional_help')}
            </Typography>
          </Box>
        </Box>

        <Box className="flex justify-start items-center relative sm:w-1/2 flex-shrink-0 p-4">
          <DronePropertiesCard droneData={droneData} error={error} />
        </Box>
      </Box>
    </SimpleHeaderLayout>
  );
}

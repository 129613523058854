import * as Yup from 'yup';

const getEditDetailsSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    fullName: Yup.string().required(t('signup_page.errors.required')),
    email: Yup.string().email(t('signup_page.errors.email_format')).required(t('signup_page.errors.required')),
    preferred_language: Yup.string().required(t('signup_page.errors.required'))
  });

export default getEditDetailsSchema;

import React from 'react';

import { Typography } from '@mui/joy';

interface SideNavHeaderItemProps {
  title: string;
}

const SideNavHeaderItem: React.FC<SideNavHeaderItemProps> = ({ title }) => (
  <Typography sx={{ padding: '24px 0px 8px 0px', color: 'text.tertiary' }}>{title}</Typography>
);

export default SideNavHeaderItem;

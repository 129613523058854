import * as Yup from 'yup';

const CampaignSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    name: Yup.string().required(t('general_errors.required')),
    description: Yup.string().required(t('general_errors.required')),
    status: Yup.number().required(t('general_errors.required'))
  });

export default CampaignSchema;

import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { CssVarsProvider } from '@mui/joy';

import sessionStore from '../stores/SessionStore';
import sifly from '../themes/sifly';

export type Mode = 'light' | 'dark' | 'default';

interface ThemeContextType {
  mode: Mode;
  changeTheme: (mode: Mode) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<Mode>(
    (sessionStore.user?.color_scheme_preference as Mode) || 'default'
  );

  return (
    <ThemeContext.Provider value={{ mode, changeTheme: setMode }}>
      <CssVarsProvider disableTransitionOnChange theme={sifly}>
        {children}
      </CssVarsProvider>
    </ThemeContext.Provider>
  );
};

export const useCustomTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useCustomTheme must be used within a ThemeProvider');
  }
  return context;
};

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/joy';

import { useApiService } from '../../../hooks/useAPIService';
import { ConfigOnlyDroneModelInput, DroneModel } from '../../../models/DroneModel/DroneModel.model';
import { DroneModelService } from '../../../services/DroneModelService';
import { serializeDroneModelEditConfigToBackend } from '../../../services/serializers/DroneModelSerializer';
import { getDroneModelConfigFormSchema } from '../droneModelFormSchema';
import DroneModelConfigForm from './DroneModelConfigForm';

interface DroneModelConfigEditProps {
  drone: DroneModel;
  onSuccessfulSave: (data: DroneModel) => void;
  onCancel: () => void;
}

const DroneModelConfigEdit = ({ drone, onSuccessfulSave, onCancel }: DroneModelConfigEditProps) => {
  const { t } = useTranslation();
  const { loading, callService } = useApiService(t);
  const schema = getDroneModelConfigFormSchema(t);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      configuration: drone.configuration
    }
  });

  const { handleSubmit } = methods;

  const onSubmit = async (configData: ConfigOnlyDroneModelInput) => {
    try {
      const droneData = serializeDroneModelEditConfigToBackend(drone.id, configData);

      const response = await callService({
        serviceFunction: DroneModelService.updateDroneModelConfig,
        args: droneData
      });

      if (response.success && response.data) {
        onSuccessfulSave(response.data);
      } else {
        toast.error(t('drone_models.errors.update_drone_model_config'));
      }
    } catch (err: unknown) {
      toast.error(t('drone_models.errors.update_drone_model_config'));
      console.error('Error submitting form:', err); // TODO: Handle error and display translation
    }
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '90%' // Full height card
        }}
      >
        <DroneModelConfigForm />
        <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
          <Button color="neutral" type="submit" variant="solid">
            {t('general_button_labels.save_changes')}
          </Button>
          <Button color="neutral" onClick={onCancel} variant="outlined">
            {t('general_button_labels.cancel')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default DroneModelConfigEdit;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from 'react';

import Image from '../models/Image';

export interface ImageDisplayProps {
  playing: boolean
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({
  playing
}) => {
  const [imageMetadata, setImageMetadata] = useState<Image | undefined>(undefined);
  const [lastCreatedUrl, setLastCreatedUrl] = useState<string | undefined>(undefined);

  const fetchImage = async (imageUrl: string): Promise<string | undefined> => {
    const response = await fetch(imageUrl);

    try {
      if (response.ok) {
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        return objectUrl;
      }
    }
    catch (error) {
      console.error('Error fetching image:', error);
    }

    // TODO: the image cannot be fetched, show a toast about this

    return undefined;
  }

  useEffect(() => {
    const protocol = process.env.REACT_APP_SERVER_WEBSOCKET_PROTOCOL || 'wss';
    const hostname = process.env.REACT_APP_SERVER_HOSTNAME || window.location.hostname;
    const imageWsCreated = new WebSocket(`${protocol}://${hostname}/ws/drone`);

    imageWsCreated.onmessage = (event: MessageEvent) => {
      const imageMetadataFetched = JSON.parse(event.data);
      setImageMetadata(imageMetadataFetched);
    }

    return () => imageWsCreated.close();
  }, []);

  const updateImage = async (): Promise<void> => {
    if (playing && imageMetadata) {
      const lastCreatedUrlReturned = await fetchImage(imageMetadata?.image_url);

      if (lastCreatedUrl) {
        URL.revokeObjectURL(lastCreatedUrl);
      }

      setLastCreatedUrl(lastCreatedUrlReturned);
    }
  }

  useEffect(() => {
    updateImage(); // TODO: check what happens if update of imageMetadata?.image_url is faster than fetching speed and if fetch calls stack up in the event queue
  }, [playing, imageMetadata?.image_url]);

  return (
    <img
      src={lastCreatedUrl}
      alt='Raster image from server'
      style={{
        width: 'auto',
        maxHeight: '100%'
      }}
    />
  );
};

export default ImageDisplay;

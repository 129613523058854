import React from 'react';

import { Box } from '@mui/joy';

import LanguageSwitcher from "../LanguageSwitcher";

const VideoLayout: React.FC<React.PropsWithChildren> = (props) => (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
      <Box sx={{ position: 'absolute', top: 0, right: 0, p: 4, zIndex: 10 }}>
        <LanguageSwitcher />
      </Box>
      <Box sx={{ position: 'relative', flexShrink: 0, width: { sm: '60%' } }}>
        <img
          alt="Sifly Logo"
          className="absolute top-0 left-0 h-5 w-28 font-medium m-5 z-10"
          src="images/logos/logo.png"
        />
        <video autoPlay className="sm:min-h-full max-w-full object-fill sm:object-none" loop
muted>
          <source src="/videos/siflylogin.mp4" type="video/mp4" />
        </video>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: { sm: '90vh' },
          p: 6,
          width: { sm: '40%' }
        }}
      >
        {props.children}
      </Box>
    </Box>
  );

export default VideoLayout;

import { PERMISSIONS_URL } from '../constants/Permissions';
import { Organization } from '../models/Company';
import { apiClient } from './ApiService';

const getRoles = async (): Promise<Organization[]> => {
  try {
    return await apiClient.get<Organization[]>(PERMISSIONS_URL, {}, false);
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

export const RoleService = { getRoles };

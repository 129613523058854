import { useTranslation } from 'react-i18next';
import { Chip, Typography } from '@mui/joy';
import { CampaignStatus } from '../../models/CampaignStatus.enum';

export const CampaignBadge = ({ status }: { status: CampaignStatus }) => {
  const { t } = useTranslation();
  switch (status) {
    case CampaignStatus.ended:
      return (
        <Chip size='md' className='h-2' color='danger' variant='outlined'>
          <Typography level='body-xs'>{t('campaigns.badge.ended')}</Typography>
        </Chip>
      );
    case CampaignStatus.neverStarted:
      return (
        <Chip size='md' className='h-2' color='warning'>
          <Typography level='body-xs'>{t('campaigns.badge.never_started')}</Typography>
        </Chip>
      );
    case CampaignStatus.ongoing:
      return (
        <Chip size='md' className='h-2' color='success'>
          <Typography level='body-xs'>{t('campaigns.badge.ongoing')}</Typography>
        </Chip>
      );
    default:
      return (
        <Chip size='md' className='h-2' color='neutral'>
          <Typography level='body-xs'>{t('campaigns.badge.unknown')}</Typography>
        </Chip>
      );
  }
};

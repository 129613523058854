import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import sessionStore from '../stores/SessionStore';

const ProtectedRoute: React.FC<{ redirectPath?: string }> = observer(({ redirectPath = '/' }) => {
  const location = useLocation();

  if (!sessionStore.isAuthenticated) {
    return <Navigate replace state={{ from: location }} to={redirectPath} />;
  }

  return <Outlet />;
});

export default ProtectedRoute;

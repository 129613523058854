import * as Yup from 'yup';

const noEmptySpaces = (message: string) => Yup.string().matches(/^\S*$/, message);

const getAccountFormSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    email: noEmptySpaces(t('signup_page.errors.empty_email'))
      .email(t('signup_page.errors.email_format'))
      .required(t('signup_page.errors.required')),

    firstName: noEmptySpaces(t('signup_page.errors.empty_first_name')).required(t('signup_page.errors.required')),

    lastName: noEmptySpaces(t('signup_page.errors.empty_last_name')).required(t('signup_page.errors.required')),

    callSign: noEmptySpaces(t('signup_page.errors.empty_call_sign')).required(t('signup_page.errors.required')),

    password: Yup.string().min(12, t('signup_page.errors.password_length')).required(t('signup_page.errors.required')),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('signup_page.errors.password_mismatch'))
      .required(t('signup_page.errors.required'))
  });

export default getAccountFormSchema;

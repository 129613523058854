import {
  MdBatteryFull,
  MdOutlineBattery1Bar,
  MdOutlineBattery2Bar,
  MdOutlineBattery4Bar,
  MdOutlineBattery5Bar
} from 'react-icons/md';

export const getBatteryIcon = (batteryPercentage: number) => {
  if (batteryPercentage < 20) {
    return <MdOutlineBattery1Bar color="#f44336" size={18} />;
  } if (batteryPercentage >= 20 && batteryPercentage < 45) {
    return <MdOutlineBattery2Bar color="#1F7A1F" size={18} />;
  } if (batteryPercentage >= 45 && batteryPercentage <= 55) {
    return <MdOutlineBattery4Bar color="#1F7A1F" size={18} />;
  } if (batteryPercentage > 55 && batteryPercentage < 95) {
    return <MdOutlineBattery5Bar color="#1F7A1F" size={18} />;
  } if (batteryPercentage >= 95) {
    return <MdBatteryFull color="#1F7A1F" size={18} />;
  }
  return null;
};

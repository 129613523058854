import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Button, Typography, useTheme } from '@mui/joy';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

import VideoLayout from '../components/layout/VideoLayout';
import { TextInputField } from '../components/TextInputField';
import { Mode, useCustomTheme } from '../contexts/ThemeContext';
import loginFormSchema from '../schema/loginFormSchema';
import { ValidationException } from '../services/Exceptions';
import { SessionService } from '../services/SessionService';
import sessionStore from '../stores/SessionStore';

interface FormValues {
  email: string;
  password: string;
}

function Login() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState<FormValues>({
    email: searchParams.get('email') || '',
    password: ''
  });

  const token = searchParams.get('token') || '';
  const org = searchParams.get('org') || '';
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { changeTheme } = useCustomTheme();

  useEffect(() => {
    if (sessionStore.isAuthenticated) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  const validateForm = async (): Promise<boolean> => {
    const schema = loginFormSchema(t);
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      if (validationErrors instanceof Yup.ValidationError) {
        const formErrors: Record<string, string> = {};
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = error.message;
          }
        });

        setErrors(formErrors);
      }
      return false;
    }
  };

  const handleLogin = async () => {
    const isValid = await validateForm();
    if (!isValid) return;

    try {
      const resp = await SessionService.login(formData.email, formData.password);
      sessionStore.setIsAuthenticated(true);
      sessionStore.setUser(resp.data.user);
      sessionStore.saveSession();
      await SessionService.getCSRFToken();
      let redirectTo = '';

      if (token && org) {
        redirectTo = `/organization-list?token=${token}&org=${org}`;
      } else {
        redirectTo = '/dashboard';
      }

      changeTheme(sessionStore.user?.color_scheme_preference as Mode);
      navigate(redirectTo, { replace: true });
    } catch (error) {
      if (error instanceof ValidationException) {
        toast.error(t('login_page.errors.invalid_credentials'));
      } else {
        console.error('Failed to login', error);
        toast.error(t('login_page.login_failed'));
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (

    <VideoLayout>
      <Box sx={{ maxWidth: '28rem' }}>
        <Typography
          level="h2"
          sx={{
            fontSize: '2.5rem',
            lineHeight: '3rem',
            fontWeight: 600,
            marginBottom: '1.5rem',
            fontFamily: theme.fontFamily.display
          }}
        >
          {t('login_page.sign_in_to_your_account')}
        </Typography>
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <TextInputField
            error={errors.email}
            label={t('login_page.email_address')}
            name="email"
            onChange={handleInputChange}
            placeholder={t('login_page.email_address')}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            value={formData.email}
          />
          <TextInputField
            error={errors.password}
            label={t('login_page.password')}
            name="password"
            onChange={handleInputChange}
            placeholder={t('login_page.password')}
            sx={{ paddingX: '1rem', paddingY: '0.75rem' }}
            type="password"
            value={formData.password}
          />

          <Button
            color="neutral"
            sx={{
              width: '100%',
              paddingY: '0.75rem',
              paddingX: '1.5rem',
              marginTop: '1rem',
              fontWeight: 400,
              height: '3rem'
            }}
            type="submit"
          >
            {t('login_page.log_in')}
          </Button>

          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link className="underline" to="/reset-password">
              {t('login_page.reset_password')}
            </Link>

            <span className="flex justify-center font-normal text-base">
              <Typography style={{ color: 'black', marginRight: '0.5rem' }}>
                {t('login_page.dont_have_account')}
              </Typography>
              <Link className="underline" to="/signup">
                {t('login_page.create_one')}
              </Link>
            </span>
          </Box>
        </form>
      </Box>
    </VideoLayout>
  );
}

export default observer(Login);

/* eslint-disable react/jsx-sort-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/joy';

import { ReactComponent as MenuOpen } from '../../assets/images/MenuOpen.svg';
import { formatTimestamp } from '../../general-utilities/time-util';

type CampaignActivityFeedProps = {
  props?: string
}

type CampaignActivityItemProps = {
  initials: string,
  username: string,
  message: string,
  time: Date
}

const CampaignActivityItem: React.FC<CampaignActivityItemProps> = ({
  initials,
  username,
  message,
  time
}) => {
  const { i18n } = useTranslation();

  return (
    <Box className='flex flex-row h-24 border-b'
      sx={{
        borderColor: 'divider'
      }}
    >
      <Box className='flex shrink-0 w-8 h-8 m-2 items-center justify-center font-bold text-base border-2 rounded-2xl'
        sx={{
          backgroundColor: 'primary.solid.Bg',
          borderColor: 'divider',
          color: 'primary.solid.Color'
        }}
      >
        {initials.toUpperCase()}
      </Box>
      <Box className='flex flex-col flex-1 mr-2 justify-start'>
        <Box className='flex flex-row w-full items-start mt-2 justify-between'>
          <Box>
            {username}
          </Box>
          <Box>
            {formatTimestamp(time.toString(), i18n.language, { onlyHourAndMinute: true })}
          </Box>
        </Box>
        <Box className='flex flex-row w-full items-start justify-start overflow-hidden'>
          {message}
        </Box>
      </Box>
    </Box>
  );
}

type CampaignActivityInfo = {
  initials: string,
  username: string,
  message: string,
  time: Date
}

const CampaignActivityFeed: React.FC<CampaignActivityFeedProps> = () => {
  const campaignActivities: CampaignActivityInfo[] = [
    {
      initials: 'PP',
      username: 'Peter',
      message: 'The weather is not very nice today.',
      time: new Date(new Date().setHours(11, 20, 0, 0))
    },
    {
      initials: 'AE',
      username: 'Ahsan',
      message: 'Let\'s have some action today guys!!',
      time: new Date(new Date().setHours(11, 20, 0, 0))
    },
    {
      initials: 'ET',
      username: 'Emre',
      message: 'Hello there!! How are you doing today guys?',
      time: new Date(new Date().setHours(11, 15, 0, 0))
    }
  ]

  return (
    <Box className='w-[15%]'>
      <Box className='flex flex-row px-4 py-3 items-center justify-between border-y'
        sx={{
          borderColor: 'divider'
        }}>
        Mission Activity Feed
        <MenuOpen
          style={{
            stroke: 'text/icon'
          }}
        />
      </Box>
      {campaignActivities.map(ca => <CampaignActivityItem
        initials={ca.initials}
        username={ca.username}
        message={ca.message}
        time={ca.time}
      />)}
    </Box>
  );
}

export default CampaignActivityFeed;
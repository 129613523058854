import { ToastContainer } from 'react-toastify';

import { useColorScheme } from '@mui/joy/styles';

import 'react-toastify/dist/ReactToastify.css';
import siflyTheme from '../themes/sifly';

const ThemedToastContainer = () => {
  const { mode } = useColorScheme();

  type ToastType = 'default' | 'success' | 'error' | 'info' | 'warning';

  const getToastStyle = (type: ToastType) => {
    const baseStyles = mode === 'dark' ? siflyTheme.colorSchemes.dark : siflyTheme.colorSchemes.light;

    const styles: Record<ToastType, { backgroundColor: string; color: string }> = {
      success: {
        backgroundColor: baseStyles.palette.success[100],
        color: baseStyles.palette.success.plainColor
      },
      error: {
        backgroundColor: baseStyles.palette.danger[100],
        color: baseStyles.palette.danger.plainColor
      },
      info: {
        backgroundColor: baseStyles.palette.primary[100],
        color: baseStyles.palette.primary.plainColor
      },
      warning: {
        backgroundColor: baseStyles.palette.warning[100],
        color: baseStyles.palette.warning.plainColor
      },
      default: {
        backgroundColor: baseStyles.palette.background.surface,
        color: baseStyles.palette.primary.plainColor
      }
    };

    return styles[type] || styles.default;
  };

  return (
    <ToastContainer
      autoClose={5000}
      closeOnClick
      draggable
      hideProgressBar={false}
      newestOnTop={false}
      pauseOnFocusLoss
      pauseOnHover
      position="bottom-center"
      progressClassName={mode === 'dark' ? 'toastify-progress-dark' : 'toastify-progress-light'}
      /* progressStyle={{
        background:
          mode === 'dark'
            ? siflyTheme.colorSchemes.dark.palette.primary.mainChannel
            : siflyTheme.colorSchemes.light.palette.primary.mainChannel
      }} */
      rtl={false}
      toastStyle={getToastStyle('default')}
    />
  );
};

export default ThemedToastContainer;

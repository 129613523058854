import CampaignList from '../components/CampaignList';
import ResponsiveLayout from '../components/layout/ResponsiveLayout';

const Campaigns = () => (
    <ResponsiveLayout>
      <CampaignList />
    </ResponsiveLayout>
  );

export default Campaigns;

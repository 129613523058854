import React, { useCallback, useEffect } from 'react';

import { useColorScheme } from '@mui/joy/styles';

import { useCustomTheme } from '../contexts/ThemeContext';
import sessionStore from '../stores/SessionStore';

const ThemeManager: React.FC = () => {
  const { setMode } = useColorScheme();
  const { mode } = useCustomTheme();

  const applyTheme = useCallback(
    (theme: string) => {
      if (theme === 'default') {
        const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        setMode(systemPrefersDark ? 'dark' : 'light');
      } else {
        setMode(theme === 'light' ? 'light' : 'dark');
      }
    },
    [setMode]
  );

  useEffect(() => {
    const preferredTheme = sessionStore.user?.color_scheme_preference || 'default';
    applyTheme(preferredTheme);

    const systemThemeListener = window.matchMedia('(prefers-color-scheme: dark)');

    const handleThemeChange = (event: MediaQueryListEvent) => {
      if (sessionStore.user?.color_scheme_preference === 'default') {
        applyTheme(event.matches ? 'dark' : 'light');
      }
    };

    systemThemeListener.addEventListener('change', handleThemeChange);

    return () => {
      systemThemeListener.removeEventListener('change', handleThemeChange);
    };
  }, [applyTheme]);

  useEffect(() => {
    applyTheme(mode);
  }, [mode, applyTheme]);

  return null;
};

export default ThemeManager;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/joy';

import ResponsiveLayout from '../../components/layout/ResponsiveLayout';
import sessionStore from '../../stores/SessionStore';
import { PasswordChangeCard } from './PasswordChangeCard';
import { ThemePreferenceCard } from './ThemePreferenceCard';
import { UserDetailsForm } from './UserDetailsForm';

export const AccountPreferences = () => {
  const userData = sessionStore.user;
  const [theme, setTheme] = useState(userData?.color_scheme_preference || 'default');
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEditDetails = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleSignOut = () => {
    navigate('/logout');
  };

  return (
    <ResponsiveLayout>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '90%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            px: 2
          }}
        >
          <Typography level="h2">{t('navigation.account_preferences')}</Typography>
          <Button color="neutral" onClick={handleSignOut} variant="outlined">
            {t('edit_profile.sign_out')}
          </Button>
        </Box>

        <UserDetailsForm
          handleCancelEdit={handleCancelEdit}
          handleEditDetails={handleEditDetails}
          isEditing={isEditing}
          userData={userData}
        />

        <PasswordChangeCard userData={userData} />

        <ThemePreferenceCard setTheme={setTheme} theme={theme} />
      </Box>
    </ResponsiveLayout>
  );
};

import React from 'react';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';
import Input from '@mui/joy/Input';
import { SxProps } from '@mui/system';

interface ChipsInputFieldProps<T extends FieldValues> {
  triggerValidation: (name: keyof T | (keyof T)[]) => Promise<boolean>;
  clearInput: () => void;
  field: ControllerRenderProps<T, any>;
  items: string[];
  setItems: React.Dispatch<React.SetStateAction<string[]>>;
  placeholder?: string;
  chipDeleteSize?: number;
  chipDeleteColor?: string;
  chipSx?: SxProps;
  chipDeleteSx?: SxProps;
  inputSx?: SxProps;
  containerSx?: SxProps;
}

function ChipsInputField<T extends FieldValues>({
  triggerValidation,
  clearInput,
  field,
  items,
  setItems,
  placeholder,
  chipSx = {},
  chipDeleteSx = {},
  inputSx = {},
  containerSx = {}
}: ChipsInputFieldProps<T>) {
  const { t } = useTranslation();
  const resolvedPlaceholder = placeholder || t('settings.add_item');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (typeof field.value === 'string') {
        const item = field.value.trim();
        if (item && !items.includes(item)) {
          triggerValidation(field.name as keyof T).then((isValid) => {
            if (isValid) {
              setItems([...items, item]);
              clearInput();
            }
          });
        }
      }
    }
  };

  const removeItem = (item: string) => {
    setItems(items.filter((i) => i !== item));
  };

  return (
    <Box sx={{ ...containerSx }}>
      {items.map((item, index) => (
        <Chip
          endDecorator={<ChipDelete onDelete={() => removeItem(item)} sx={{ ...chipDeleteSx }} variant="plain" />}
          key={index}
          sx={{ ...chipSx }}
          variant="soft"
        >
          {item}
        </Chip>
      ))}
      <Input
        {...field}
        onKeyDown={handleKeyDown}
        placeholder={items.length === 0 ? resolvedPlaceholder : ''}
        sx={{ flexGrow: 1, minWidth: '100px', ...inputSx }}
        value={field.value as string}
        variant="plain"
      />
    </Box>
  );
}

export default ChipsInputField;

import React from 'react';

import { Card, CardContent } from '@mui/joy';

interface UserProfileCardProps {
  children: React.ReactNode;
}

export const UserProfileCard: React.FC<UserProfileCardProps> = ({ children }) => {
  const cardStyle = {
    width: '67%',
    mx: 'auto',
    mb: 4,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none'
  };

  return (
    <Card sx={cardStyle}>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Box, Breadcrumbs, Typography } from '@mui/joy';

import { BreadcrumbItem } from '../../models/BreadcrumbItem';

interface BreadcrumbLayoutProps {
  breadcrumbItems: BreadcrumbItem[];
  children: ReactNode;
  withSideNavigation?: boolean; // if this component is used in a page with side navigation: true, otherwise default: false
  controls?: ReactNode;
}

const BreadcrumbLayout: React.FC<BreadcrumbLayoutProps> = ({
  breadcrumbItems,
  children,
  withSideNavigation = true,
  controls
}) => (
  <Box padding={withSideNavigation ? 0 : 2}>
    <Box alignItems="flex-start" display={controls ? 'flex' : 'block'} justifyContent="center">
      <Breadcrumbs sx={{ paddingTop: '0' }}>
        {breadcrumbItems.map((breadcrumb, index) => breadcrumb.link
          ? <Link key={index} to={breadcrumb.link}>
            <Typography level="h4">{breadcrumb.text}</Typography>
          </Link>
          : <Typography key={index} level="h4">
            {breadcrumb.text}
          </Typography>
        )}
      </Breadcrumbs>
      {controls && <Box ml="auto">{controls}</Box>}
    </Box>
    <Box mt={2}>{children}</Box>
  </Box>
);

export default BreadcrumbLayout;

/* eslint-disable no-undef */
/*
 * gstwebrtc-api
 *
 * Copyright (C) 2022 Igalia S.L. <info@igalia.com>
 *   Author: Loïc Le Page <llepage@igalia.com>
 *
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/.
 */

/**
 * GStreamer WebRTC configuration.
 * <p>You can override default values by defining configuration before receiving the <i>DOMContentLoaded</i> event.<br>
 * Once the <i>DOMContentLoaded</i> event triggered, changing configuration will have no effect.</p>
 * <p>For example:
 * <pre>
 *     const signalingProtocol = window.location.protocol.startsWith("https") ? "wss" : "ws";
 *     window.gstWebRTCConfig = {
 *         meta: { name: `WebClient-${Date.now()}` },
 *         signalingServerUrl: `${signalingProtocol}://${window.location.host}/webrtc`
 *     };
 * </pre></p>
 * @typedef {object} gstWebRTCConfig
 * @property {object} meta=null - Client free-form information that will be exchanged with all peers through the
 * signaling <i>meta</i> property, its content depends on your application.
 * @property {string} signalingServerUrl=ws://127.0.0.1:8443 - The WebRTC signaling server URL.
 * @property {number} reconnectionTimeout=2500 - Timeout in milliseconds to reconnect to the signaling server in
 * case of an unexpected disconnection.
 * @property {object} webrtcConfig={iceServers...} - The WebRTC peer connection configuration passed to
 * {@link external:RTCPeerConnection}. Default configuration only includes a list of free STUN servers
 * (<i>stun[0-4].l.google.com:19302</i>).
 */

/**
 * Note: Julian Oes
 * I'm just overwriting this default configuration because I could not get it to work any other way.
 */
const signalingProtocol = window.location.protocol.startsWith("https")
  ? "wss"
  : "ws";
const {hostname} = window.location;

const defaultConfig = Object.freeze({
  meta: { name: `WebClient-${Date.now()}` },
  signalingServerUrl: `${signalingProtocol}://${hostname}:8443/webrtc`,
  reconnectionTimeout: 2500,
  webrtcConfig: {
    iceServers: [
      {
        urls: [
          "stun:stun.l.google.com:19302",
          "stun:stun1.l.google.com:19302"
        ]
      }
    ]
  }
});

export { defaultConfig as default };

// src/components/DynamicTable.tsx

import * as React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/joy';
import Box from '@mui/joy/Box';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';

interface TableColumn<T> {
  header: string;
  key: keyof T;
  render?: (value: any, row: T) => React.ReactNode;
  // Optional: Add more configurations like width, alignment, etc.
}

interface DynamicTable2Props<T> {
  columns: TableColumn<T>[];
  data: T[];
  selectedRows: T[];
  onSelectionChange?: (selectedRows: T[]) => void;
  onRowClick?: (row: T) => void;
  getRowKey: (row: T) => string;
  actions?: (row: T) => React.ReactNode; // For row-specific actions like edit/delete
  disableSelectionFor?: (row: T) => boolean; // Function to determine if a row should be non-selectable
  paginationOptions?: {
    rowsPerPageOptions?: number[];
    defaultRowsPerPage?: number;
    // You can add more pagination-related props as needed
  };
  className?: string;
  style?: React.CSSProperties;
  title: string;
  hasCheckbox?: boolean;
}

function DynamicTable2<T>({
  columns,
  data,
  selectedRows,
  onSelectionChange,
  onRowClick,
  getRowKey,
  actions,
  disableSelectionFor,
  paginationOptions,
  className,
  style,
  title,
  hasCheckbox = true
}: DynamicTable2Props<T>) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(paginationOptions?.defaultRowsPerPage || 10);

  // Update selected state when selectedRows prop changes
  React.useEffect(() => {
    setSelected(selectedRows.map((row) => getRowKey(row)));
  }, [selectedRows, getRowKey]);

  // Map selected keys to row data
  const mapKeysToRows = (keys: readonly string[]): T[] => keys.map((key) => data.find((row) => getRowKey(row) === key)).filter(Boolean) as T[];

  // Handle select all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.filter((row) => !disableSelectionFor?.(row)).map((row) => getRowKey(row));
      setSelected(newSelected);
      if (onSelectionChange) {
        onSelectionChange(mapKeysToRows(newSelected));
      }
    } else {
      setSelected([]);
      if (onSelectionChange) {
        onSelectionChange([]);
      }
    }
  };

  // Handle individual row selection
  const handleRowClick = (event: React.MouseEvent<unknown>, key: string, row: T) => {
    if (onRowClick) {
      onRowClick(row);
    }
    if (disableSelectionFor?.(row)) {
      return;
    }

    const selectedIndex = selected.indexOf(key);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else {
      newSelected = selected.filter((selectedKey) => selectedKey !== key);
    }

    setSelected(newSelected);
    onSelectionChange?.(mapKeysToRows(newSelected));
  };

  // Handle page change
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    if (newValue) {
      setRowsPerPage(newValue);
      setPage(0);
    }
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const getLabelDisplayedRowsTo = () => {
    if (data.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1 ? data.length : Math.min(data.length, (page + 1) * rowsPerPage);
  };

  return (
    <>
      <Table aria-label={title} className={className} style={style}>
        <thead>
          <tr>
            {hasCheckbox && (
              <th>
                <Checkbox
                  checked={
                    data.filter((row) => !disableSelectionFor?.(row)).length > 0 &&
                    selected.length === data.filter((row) => !disableSelectionFor?.(row)).length
                  }
                  indeterminate={
                    selected.length > 0 && selected.length < data.filter((row) => !disableSelectionFor?.(row)).length
                  }
                  onChange={handleSelectAllClick}
                />
              </th>
            )}

            {columns.map((column) => (
              <th key={String(column.key)} style={{ padding: '8px', textAlign: 'left' }}>
                {column.header}
              </th>
            ))}
            {actions && <th style={{ padding: '8px', textAlign: 'left' }}>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            const key = getRowKey(row);
            const isItemSelected = isSelected(key);

            return (
              <tr
                key={key}
                onClick={(event) => handleRowClick(event, key, row)}
                style={{
                  backgroundColor: isItemSelected ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
                  cursor: disableSelectionFor?.(row) ? 'not-allowed' : 'pointer'
                }}
              >
                {hasCheckbox && (
                  <td>
                    <Checkbox checked={isItemSelected} disabled={disableSelectionFor?.(row)} />
                  </td>
                )}
                {columns.map((column) => (
                  <td key={String(column.key)} style={{ padding: '8px' }}>
                    {column.render ? column.render(row[column.key], row) : String(row[column.key])}
                  </td>
                ))}
                {actions && <td>{actions(row)}</td>}
              </tr>
            );
          })}
          {emptyRows > 0 && (
            <tr style={{ height: 53 * emptyRows }}>
              <td aria-hidden='true' colSpan={columns.length + (actions ? 2 : 1)} />
            </tr>
          )}
        </tbody>
      </Table>
      {/* Pagination Controls */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'flex-end',
          mt: 2
        }}
      >
        {paginationOptions && (
          <Select onChange={handleChangeRowsPerPage} sx={{ minWidth: 80 }} value={rowsPerPage}>
            {(paginationOptions.rowsPerPageOptions || [10, 25, 50]).map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        )}
        <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
          {`${page * rowsPerPage + 1}-${getLabelDisplayedRowsTo()} of ${data.length}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton disabled={page === 0} onClick={() => handleChangePage(page - 1)} size="sm">
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            disabled={data.length !== -1 ? page >= Math.ceil(data.length / rowsPerPage) - 1 : false}
            onClick={() => handleChangePage(page + 1)}
            size="sm"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default DynamicTable2;

// src/components/DroneModelsTable.tsx

import React from 'react';
import { useTranslation } from 'react-i18next';

import { DroneModel } from '../../models/DroneModel/DroneModel.model';
import DynamicTable from '../DynamicTable2';

// Backend request representation of a drone model
export type DroneModelEdit = Omit<DroneModel, 'createdAt' | 'updatedAt'>;
export type DroneModelEditBasic = Pick<DroneModelEdit, 'id' | 'name' | 'manufacturer' | 'type' | 'description'>;
export type DroneModelEditConfig = Pick<DroneModelEdit, 'id' | 'configuration'>;
export type DroneModelCreate = Omit<DroneModel, 'id' | 'createdAt' | 'updatedAt'>;

type DroneModelColumn = {
  header: string;
  key: keyof DroneModel;
  render?: (value: string | Date) => string;
};

interface DroneModelsTableProps {
  data: DroneModel[];
  selectedRows: DroneModel[];
  onRowClick?: (row: DroneModel) => void;
  onSelectionChange?: (selected: DroneModel[]) => void;
}

const DroneModelsTable: React.FC<DroneModelsTableProps> = ({ data, selectedRows, onSelectionChange, onRowClick }) => {
  const { t } = useTranslation();
  const columns: DroneModelColumn[] = [
    { header: t('drone_models.name'), key: 'name' },
    { header: t('drone_models.manufacturer'), key: 'manufacturer' },
    { header: t('drone_models.type'), key: 'type' },
    { header: t('drone_models.description'), key: 'description' },
    {
      header: t('drone_models.created_at'),
      key: 'createdAt',
      render: (value: string | Date) => new Date(value).toLocaleDateString()
    }
  ];

  const getRowKey = (row: DroneModel) => row.id;

  const disableSelectionFor = () => false; // Modify if certain rows should not be selectable

  return (
    <DynamicTable
      columns={columns}
      data={data}
      disableSelectionFor={disableSelectionFor}
      getRowKey={getRowKey}
      hasCheckbox={false}
      onRowClick={onRowClick}
      onSelectionChange={onSelectionChange}
      selectedRows={selectedRows}
      title={t('drone_models.title')}
    />
  );
};

export default DroneModelsTable;

export const trimStrings = <T extends Record<string, any>>(input: T): T => {
  const trimmed = {} as T;
  Object.keys(input).forEach((key) => {
    if (typeof input[key] === 'string') {
      (trimmed as any)[key] = input[key].trim();
    } else {
      (trimmed as any)[key] = input[key];
    }
  });
  return trimmed as T;
};

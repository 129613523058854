import User from '../models/User';
import { apiClient } from './ApiService';

const login = async (email: string, password: string): Promise<any | void> => apiClient.post('/account/login/', { email, password });

interface SignupResponse {
  data: User;
  message: string;
  status: number;
}

const signup = async (
  first_name: string,
  last_name: string,
  username: string,
  email: string,
  password: string
): Promise<SignupResponse> => apiClient.post(
  '/account/signup/',
  {
    first_name,
    last_name,
    username,
    email,
    password
  }
);

interface VerifyEmailResponse {
  success: boolean;
  message: string;
}

const verifyEmail = async (token: string): Promise<VerifyEmailResponse> => apiClient.post(`/account/verify/${token}/`, {});

const apiPing = async (): Promise<any | void> => apiClient.get('/ping');

const getCSRFToken = (): Promise<{ csrfToken: string }> => apiClient.get('/account/getcsrf/');

const getSession = async (): Promise<any | void> => apiClient.get('/getsession');

// Utility function for getting cookies
function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const logout = async (): Promise<any | void> => apiClient.post('/account/logout/', {});

const getUser = async (): Promise<any | void> => apiClient.get('/user-data');

/**
 *
 * @returns {message: string}
 * redirects to login if not authenticated upstream
 */
const validateSession = async (): Promise<{ message: string }> => apiClient.get('/account/validate-session');

export const SessionService = {
  login,
  signup,
  verifyEmail,
  apiPing,
  getCSRFToken,
  getSession,
  logout,
  getUser,
  validateSession
};

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/joy';

import DroneProperties from '../components/DroneProperties';
import ResponsiveLayout from '../components/layout/ResponsiveLayout';
import { DroneProfile } from '../models/DroneProfile/DroneProfile.model';
import { DroneProfileService } from '../services/DroneProfileService';

export default function DroneOverview() {
  const { id } = useParams<{ id: string }>();
  const [droneProfileData, setDroneProfileData] = useState<DroneProfile | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchDroneProfileData = async () => {
      try {
        if (!id) {
          setError(true);
          return;
        }

        const result = await DroneProfileService.getDroneProfile(id);

        if (result.success) {
          setDroneProfileData(result.data.drone_profile);
        }
      } catch {
        setError(true);
      }
    };

    fetchDroneProfileData();
  }, [id]);

  return (
    <ResponsiveLayout>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '90vh',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1
          }}
        >
          <img
            alt="Map dummy"
            src="images/map-dummy.png"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'relative',
            zIndex: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1rem'
            }}
          >
            <DroneProperties droneProfileData={droneProfileData} error={error} />
          </Box>
        </Box>
      </Box>
    </ResponsiveLayout>
  );
}

/* eslint-disable react/jsx-sort-props */
import CampaignView from '../components/Campaign/CampaignView';
import CampaignDashboardLayout from '../components/layout/CampaignDashboardLayout';

const Campaign: React.FC = () => (
  <CampaignDashboardLayout>
    <CampaignView projectName='Project Name' campaignName='Campaign Name' />
  </CampaignDashboardLayout>
);

export default Campaign;

import { ChangeEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, FormControl, FormLabel, Textarea, Typography } from '@mui/joy';

import { ConfigOnlyDroneModelInput } from '../../../models/DroneModel/DroneModel.model';

const DroneModelConfigForm = () => {
  const {
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors }
  } = useFormContext<ConfigOnlyDroneModelInput>();

  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      if (selectedFile.type !== 'application/json') {
        setError('configuration', {
          type: 'custom',
          message: t('drone_models.errors.please_select_valid_json')
        });
        setFile(null);
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          setValue('configuration', JSON.stringify(json, null, 2));
          clearErrors('configuration');
        } catch (err) {
          console.error('Error parsing JSON:', err);
          setError('configuration', {
            type: 'custom',
            message: t('drone_models.errors.please_select_valid_json')
          });
        }
      };
      reader.onerror = () => {
        console.error('Error reading file');
        setError('configuration', { type: 'custom', message: t('drone_models.errors.file_upload_error') });
      };
      reader.readAsText(selectedFile);
      setFile(selectedFile);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        maxHeight: '400px'
      }}
    >
      {errors.configuration && (
        <Typography color="danger" mt={2} sx={{ fontSize: 'sm', '--Typography-gap': '0.5rem', p: 1 }}
variant="soft">
          {typeof errors.configuration.message === 'string' && errors.configuration.message}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 2,
          alignItems: 'center'
        }}
      >
        <Box flex={1}>
          <FormLabel htmlFor="drone-model-configuration">
            {t('drone_models.properties_label.configuration_json', { fileName: file?.name || '' })}{' '}
          </FormLabel>
        </Box>

        <Button color="neutral" component="label" sx={{ marginTop: 2 }}
variant="outlined">
          {t('drone_models.buttons.upload_json')}
          <input accept=".json,application/json" hidden onChange={handleFileUpload}
type="file" />
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: 1 }}>
        <FormControl required>
          <Controller
            control={control}
            name="configuration"
            render={({ field }) => (
              <Textarea
                autoComplete="off"
                id="drone-model-configuration"
                minRows={8}
                slotProps={{
                  textarea: { id: 'drone-model-configuration' }
                }}
                {...field}
              />
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default DroneModelConfigForm;

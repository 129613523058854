import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/joy';

import ResponsiveLayout from '../components/layout/ResponsiveLayout';

const Dashboard: React.FC = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { t } = useTranslation();

  return (
    <ResponsiveLayout>
      <Typography> {t('navigation.dashboard')} </Typography>
      <Typography>
        {t('dashboard.welcome')}, {user?.first_name || user?.username || t('role_assignment.user')}!
      </Typography>
      <Typography>
        {t('edit_profile.email')}: {user?.email || t('dashboard.n_a')}
      </Typography>
    </ResponsiveLayout>
  );
};

export default Dashboard;

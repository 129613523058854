import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/joy';

import { TextInputField } from '../../components/TextInputField';
import { UserProfileCard } from '../../components/UserProfileCard';
import User from '../../models/User';
import { getPasswordChangeSchema } from '../../schema/passwordChangeSchema';
import { UserService } from '../../services/UserService';

interface PasswordChangeCardProps {
  userData: User | null;
}

interface PasswordChangeForm {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export const PasswordChangeCard: React.FC<PasswordChangeCardProps> = ({ userData }) => {
  const { t } = useTranslation();
  const schema = getPasswordChangeSchema(t);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(schema)
  });

  const [isChangingPassword, setIsChangingPassword] = useState(false);

  useEffect(() => {
    reset({
      currentPassword: '',
      password: '',
      confirmPassword: ''
    });
  }, [userData, reset]);

  const handleChangePassword = () => setIsChangingPassword(true);

  const handleCancelChange = () => {
    setIsChangingPassword(false);
    reset();
  };

  const onSubmit: SubmitHandler<PasswordChangeForm> = async (data: PasswordChangeForm) => {
    try {
      if (userData) {
        await UserService.changeUsernamePassword(userData.id, data.currentPassword, data.password);
        setIsChangingPassword(false);
        toast.success(t('edit_profile.password_updated'));
      }
    } catch (error) {
      setIsChangingPassword(false);
      toast.error(`${t('edit_profile.errors.update_password')}: ${error}`);
    }
  };

  return (
    <UserProfileCard>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography level="h3">{t('edit_profile.password_change')}</Typography>
        {!isChangingPassword && (
          <Button color="neutral" onClick={handleChangePassword} variant="outlined">
            {t('edit_profile.change_password')}
          </Button>
        )}
      </Box>
      <Typography sx={{ pb: 2 }}>{t('edit_profile.password_validation')}</Typography>

      {isChangingPassword && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="currentPassword"
            render={({ field }) => (
              <TextInputField
                label={t('edit_profile.current_password')}
                type="password"
                {...field}
                classNames="lg:w-1/2"
                error={errors.currentPassword ? errors.currentPassword.message : ''}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextInputField
                label={t('edit_profile.new_password')}
                type="password"
                {...field}
                classNames="lg:w-1/2"
                error={errors.password ? errors.password.message : ''}
              />
            )}
          />

          <Controller
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <TextInputField
                label={t('signup_page.confirm_password')}
                type="password"
                {...field}
                classNames="lg:w-1/2"
                error={errors.confirmPassword ? errors.confirmPassword.message : ''}
              />
            )}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
            <Button color="neutral" disabled={isSubmitting} type="submit">
              {isSubmitting ? t('edit_profile.saving') : t('edit_profile.update_password')}
            </Button>
            <Button color="neutral" onClick={handleCancelChange} variant="outlined">
              {t('edit_profile.cancel')}
            </Button>
          </Box>
        </form>
      )}
    </UserProfileCard>
  );
};

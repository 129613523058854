import { useEffect } from 'react';
import { redirect } from 'react-router-dom';

import sessionStore from '../stores/SessionStore';
import { SessionService } from '../services/SessionService';

const Logout = () => {
  useEffect(() => {
    
    if (sessionStore.isAuthenticated) {
      SessionService.logout().then(() => {
        sessionStore.setIsAuthenticated(false);
        sessionStore.clearSession();
        redirect('/');
      });
    } else {
      redirect('/');
    }
  }, []);
  return null;
};

export default Logout;

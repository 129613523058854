import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, LinearProgress, Typography } from '@mui/joy';
import { ValidationError } from 'yup';

import { SimpleHeaderLayout } from '../components/layout/SimpleHeaderLayout';
import { TextInputField } from '../components/TextInputField';
import createOrganizationSchema from '../schema/createOrgSchema';
import { CompanyService } from '../services/CompanyService';
import { ServerException } from '../services/Exceptions';
import { organizationStore } from '../stores/OrganizationStore';
import sessionStore from '../stores/SessionStore';

export const CreateOrganization = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  interface CreateOrgNameForm {
    name: string;
  }

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: { name: '' },
    resolver: yupResolver(createOrganizationSchema(t))
  });

  const onSubmit: SubmitHandler<CreateOrgNameForm> = async (data: CreateOrgNameForm) => {
    if (sessionStore.user?.id) {
      try {
        const response = await CompanyService.createOrganization(sessionStore.user.id, data.name);

        if (response) {
          const updatedOrganizations = await CompanyService.getUserOrganizations(sessionStore.user.id);

          if (updatedOrganizations?.organization_data) {
            const newOrg = updatedOrganizations.organization_data.find((org: { id: number }) => org.id === response.id);
            if (newOrg) {
              organizationStore.setSelectedOrg(newOrg.id);
              organizationStore.setOrganizations(updatedOrganizations.organization_data);
              organizationStore.persistSelectedOrg();
              toast.success(t('organization.organization_created'));
              navigate('/user-permissions');
            }
          }
        }
      } catch (error: unknown) {
        if (error instanceof ValidationError) {
          toast.error(error.message);
        } else if (error instanceof ServerException) {
          toast.error(t('general_errors.server_error'));
        } else {
          toast.error(t('organization.errors.create_org'));
        }
      }
    } else {
      toast.error(t('organization.errors.unauthenticated'));
    }
  };

  const cancelButton = (
    <Typography onClick={() => navigate('/')} sx={{ cursor: 'pointer', alignSelf: 'center', margin: '1rem' }}>
      {t('edit_profile.cancel')}
    </Typography>
  );

  return (
    <SimpleHeaderLayout controlComponent={cancelButton}>
      <Box className="flex items-center justify-center min-h-[75vh] m-4">
        <Box className="max-w-[29rem]">
          <Box sx={{ width: '65%' }}>
            <LinearProgress
              determinate
              sx={{
                marginBottom: '1.5rem',
                backgroundColor: 'primary.800',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'primary.200'
                }
              }}
              value={25}
            />
          </Box>

          <Typography level="h1">{t('organization.create_organization')}</Typography>
          <Typography sx={{ color: 'neutral.700', paddingTop: '1rem', paddingBottom: '0.5rem' }}>
            {t('organization.name_description')}
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginY: '1.5rem' }}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextInputField
                    label={t('organization.org_name')}
                    {...field}
                    error={errors.name ? errors.name.message : ''}
                    sx={{
                      width: '100%',
                      paddingX: '16px',
                      paddingY: '12px',
                      minHeight: '48px',
                      fontSize: '14px',
                      borderWidth: '1px',
                      borderRadius: '6px'
                    }}
                  />
                )}
              />
            </Box>

            <Button
              className="w-full rounded-md py-2 px-6 mt-4 text-base leading-4 font-normal h-12"
              color="neutral"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t('organization.creating_org') : t('organization.save_and_continue')}
            </Button>
          </form>
        </Box>
      </Box>
    </SimpleHeaderLayout>
  );
};

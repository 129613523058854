import { FC } from 'react';

import { Box, Typography } from '@mui/joy';

interface PageHeaderTitleProps {
  title: string;
  pageExplanation?: string; // Optional: Add a small explanation below the title
  headerControls?: React.ReactNode | (() => React.ReactNode); // Optional: Add header controls like buttons, etc.
  hasLineBreakAfterTitle?: boolean;
  hasLineBreakAfterDescription?: boolean;
}

export const PageHeaderTitle: FC<PageHeaderTitleProps> = ({
  title,
  headerControls,
  pageExplanation,
  hasLineBreakAfterDescription = true,
  hasLineBreakAfterTitle = false
}) => {
  const hasExplanationOrLineBreak = pageExplanation || hasLineBreakAfterTitle || hasLineBreakAfterDescription;
  const marginBottom = hasExplanationOrLineBreak ? 3 : 0;

  return (
    <Box
      alignItems="center"
      display={hasExplanationOrLineBreak ? 'block' : 'flex'}
      justifyContent="space-between"
      mb={marginBottom}
    >
      <Typography level="h4">{title}</Typography>

      {pageExplanation && (
        <>
          <Typography level="body-md" sx={{ mt: 1 }}>
            {pageExplanation}
          </Typography>
          {hasLineBreakAfterDescription && <Box mt={1} />}
        </>
      )}

      {headerControls && (
        <Box mt={hasExplanationOrLineBreak ? 2 : 0}>
          {typeof headerControls === 'function' ? headerControls() : headerControls}
        </Box>
      )}
    </Box>
  );
};

import { MdBarChart } from "react-icons/md";

import { Box, Typography } from '@mui/joy';

import { getBatteryIcon } from '../general-utilities/getBatteryIcon';
import { DroneProfile } from '../models/DroneProfile/DroneProfile.model';

interface DronePropertiesProps {
  droneProfileData: DroneProfile | null;
  error: boolean;
}

export default function DroneProperties({ droneProfileData, error }: DronePropertiesProps) {
  const batteryPercentage = 90;
  const connected = true;
  const signalStatus = connected ? 'Good' : 'Not connected';
  const location = droneProfileData ? `Near New York` : 'Location Not Found';

  if (error) {
    return (
      <Box
        sx={{
          borderRadius: '12px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          padding: '1rem',
          maxWidth: 'max-content',
          width: '100%',
          overflowY: 'auto',
          maxHeight: '90vh',
          textAlign: 'center'
        }}
      >
        <Typography level="h4" sx={{ fontSize: '20px', fontWeight: '600', color: 'error.main' }}>
          Error: Drone Profile does not exist.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: '12px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '1rem',
        maxWidth: 'max-content',
        width: '100%',
        overflowY: 'auto',
        maxHeight: '90vh'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <img
          alt="Drone"
          src="/images/sifly-drone-image.png"
          style={{ width: '545px', height: '231px', borderRadius: '12px' }}
        />
      </Box>

      {droneProfileData && (
        <>
          <Box sx={{ display: { sm: 'flex' }, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'inline-flex', fontWeight: '600', fontSize: '20px' }}>
              <Typography level="h4" sx={{ marginRight: '0.75rem' }}>
                {droneProfileData.name}
              </Typography>
              <Typography level="h4" sx={{ color: 'neutral.400' }}>
                {droneProfileData.eid}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <Typography
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  padding: '0.25rem 0.75rem',
                  backgroundColor: connected ? 'success.100' : 'neutral.100',
                  color: connected ? 'success.700' : 'neutral.700',
                  borderRadius: '24px',
                  marginRight: '1rem',
                  fontSize: '14px',
                  fontWeight: '500'
                }}
              >
                <MdBarChart className="mx-1" size={20} /> {signalStatus}
              </Typography>
              {connected && (
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Typography sx={{ marginRight: '0.5rem' }}>{batteryPercentage}%</Typography>
                  {getBatteryIcon(batteryPercentage)}
                </Box>
              )}
            </Box>
          </Box>

          <Typography
            level="h4"
            sx={{ fontSize: '20px', fontWeight: '600', color: 'neutral.400', marginBottom: '1rem' }}
          >
            {location}
          </Typography>

          <Typography
            level="h4"
            sx={{ fontSize: '20px', fontWeight: '600', color: 'neutral.400', marginBottom: '1rem' }}
          >
            {signalStatus}
          </Typography>

          <Typography
            level="h4"
            sx={{ fontSize: '20px', fontWeight: '600', color: 'neutral.400', marginBottom: '1rem' }}
          >
            Drone info 1
          </Typography>
          <Typography
            level="h4"
            sx={{ fontSize: '20px', fontWeight: '600', color: 'neutral.400', marginBottom: '1rem' }}
          >
            Drone info 2
          </Typography>
          <Typography
            level="h4"
            sx={{ fontSize: '20px', fontWeight: '600', color: 'neutral.400', marginBottom: '1rem' }}
          >
            Drone info 3
          </Typography>
          <Typography
            level="h4"
            sx={{ fontSize: '20px', fontWeight: '600', color: 'neutral.400', marginBottom: '1rem' }}
          >
            Drone info 4
          </Typography>
        </>
      )}
    </Box>
  );
}

import { makeAutoObservable } from 'mobx';
import User from '../models/User';

class SessionStore {
  user: User | null = null;

  csrfToken: string | null = null;

  isAuthenticated = false;

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: User | null) {
    this.user = user;
    this.setIsAuthenticated(!!user);
    this.saveSession();
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  setCsrfToken(csrfToken: string | null) {
    this.csrfToken = csrfToken;
    this.saveSession();
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  saveSession() {
    if (this.user) {
      localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      localStorage.removeItem('user');
    }

    if (this.csrfToken) {
      localStorage.setItem('csrfToken', this.csrfToken);
    } else {
      localStorage.removeItem('csrfToken');
    }
  }

  clearSession() {
    this.setUser(null);
    this.setCsrfToken(null);
    this.setIsAuthenticated(false);
  }
}
const sessionStore = new SessionStore();
export default sessionStore;

import React, { forwardRef } from 'react';

import { FormControl, FormHelperText, FormLabel, Option, Select } from '@mui/joy';
import { SxProps } from '@mui/system';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  value: string | number;
  error?: string;
  onChange: (value: string | number) => void;
  options: { value: string | number; label: string }[];
  sx?: SxProps;
}

export const SelectField = forwardRef<HTMLDivElement, Props>(
  ({ label, name, placeholder, value, error, onChange, options, sx }, ref) => (
      <FormControl className="mb-4" error={!!error} ref={ref}
sx={sx}>
        <FormLabel>{label}</FormLabel>
        <Select
          id={name}
          name={name}
          onChange={(event, newValue) => onChange(newValue!)}
          placeholder={placeholder}
          sx={sx}
          value={value as string}
        >
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )
);

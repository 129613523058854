import { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton } from '@mui/joy';

import { Navigation2 as Navigation } from './navigation/Navigation2';

interface SingleColumnLayoutProps {
  children: React.ReactNode;
}

const SingleColumnLayout = ({ children }: SingleColumnLayoutProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <Drawer onClose={toggleDrawer} open={isDrawerOpen}>
        <Navigation />
      </Drawer>
      <Box mt={2} padding={2}>
        {children}
      </Box>
    </Box>
  );
};

export default SingleColumnLayout;

import { useTranslation } from 'react-i18next';
import { MdBarChart } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Box, Button, Typography } from '@mui/joy';

import { getBatteryIcon } from '../general-utilities/getBatteryIcon';
import { DroneData, DroneState } from '../models/DroneProfile/DroneProfile.model';
import { DroneProfileService } from '../services/DroneProfileService';
import { organizationStore } from '../stores/OrganizationStore';

export default function DronePropertiesCard({ droneData, error }: { droneData: DroneData | null; error: boolean }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedOrg } = organizationStore;

  const batteryPercentage = droneData?.data?.battery?.percentage ?? 0;
  const connected = droneData ? droneData.data.state !== DroneState.SHUTDOWN : false;

  const handleRegisterDrone = async () => {
    if (!droneData) return;

    const newDroneProfile = {
      name: droneData.data.name,
      eid: droneData.data.eid,
      drone_model: '1',
      organization: selectedOrg!
    };

    try {
      await DroneProfileService.createDroneProfile(newDroneProfile);
      toast.success(t('drone_profiles.messages.drone_profile_created'));
      navigate(`/drone-overview/${droneData.data.eid}`);
    } catch {
      toast.error(t('drone_profiles.errors.failed_to_save'));
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '12px',
        border: '1px solid #CDD7E1',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '1rem',
        margin: '1rem',
        maxWidth: '464px',
        width: '100%'
      }}
    >
      <img alt="Sifly Logo" className="mb-4" src="/images/sifly-drone-image.png" />

      {droneData && (
        <>
          <Box
            sx={{
              display: { sm: 'inline-flex' },
              width: '100%',
              justifyContent: 'space-between',
              marginBottom: '0.5rem'
            }}
          >
            <Box sx={{ display: 'inline-flex' }}>
              <Typography level="h4" sx={{ marginRight: '0.75rem' }}>
                {droneData.data.name}
              </Typography>
              <Typography level="h4" sx={{ color: 'neutral.400' }}>
                {droneData.data.eid}
              </Typography>
            </Box>

            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              {connected ? (
                <>
                  <Box sx={{ display: 'flex', marginRight: '0.5rem', alignItems: 'center' }}>
                    <Typography sx={{ marginRight: '0.5rem' }}>{batteryPercentage}%</Typography>
                    {getBatteryIcon(batteryPercentage)}
                  </Box>
                  <Typography
                    sx={{
                      color: 'success.700',
                      backgroundColor: 'success.100',
                      marginLeft: '0.25rem',
                      padding: '0.25rem 0.75rem',
                      borderRadius: '24px',
                      fontSize: '0.875rem',
                      fontWeight: '500',
                      display: 'inline-flex'
                    }}
                  >
                    <MdBarChart className="mx-1" size={20} />
                    {t('register_drone.connected')}
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{
                    color: 'neutral.700',
                    backgroundColor: 'neutral.100',
                    marginLeft: '0.25rem',
                    padding: '0.25rem 0.75rem',
                    borderRadius: '24px',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    display: 'inline-flex'
                  }}
                >
                  {t('register_drone.not_connected')}
                </Typography>
              )}
            </Box>
          </Box>

          {!error && (
            <Button
              disabled={!connected}
              onClick={handleRegisterDrone}
              sx={{
                width: '100%',
                minHeight: '48px',
                marginTop: '0.5rem',
                backgroundColor: '#FFCA0F',
                fontSize: '1rem',
                fontWeight: '400'
              }}
            >
              {t('register_drone.submit_button')}
            </Button>
          )}
        </>
      )}
    </Box>
  );
}

import * as React from 'react';

import Button from '@mui/joy/Button';
import { useColorScheme } from '@mui/joy/styles';
import { SystemStyleObject } from '@mui/system';

interface ContrastButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ContrastButton: React.FC<ContrastButtonProps> = ({ children, onClick }) => {
  const { mode } = useColorScheme();

  const styles: SystemStyleObject = {
    backgroundColor: mode === 'light' ? 'var(--joy-palette-neutral-900)' : 'var(--joy-palette-neutral-100)',
    color: mode === 'light' ? 'var(--joy-palette-common-white)' : 'var(--joy-palette-common-black)',
    '&:hover': {
      backgroundColor: mode === 'light' ? 'var(--joy-palette-neutral-800)' : 'var(--joy-palette-neutral-200)'
    }
  };

  return (
    <Button onClick={onClick} sx={styles}>
      {children}
    </Button>
  );
};

export default ContrastButton;

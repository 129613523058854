/* eslint-disable no-console */
import { useEffect } from 'react';

import { useWebRTC, Producer } from '../contexts/WebRtcContext';
import { gstWebRTCAPI } from '../third-party-lib/gstwebrtc-api/gstwebrtc-api';

const ProducerManager: React.FC = () => {
  const { setAllProducers, setLatestProducer, latestProducer } = useWebRTC();

  const processAllProducers = (): void => {
    console.log('Processing all producers');
    const allProducers = gstWebRTCAPI.getAvailableProducers() as Producer[];
    setAllProducers(allProducers);
    console.log('All producers', allProducers);
  };

  const listenForProducers = (): void => {
    const producerListener = {
      producerAdded: (producer: Producer) => {
        setLatestProducer(producer);
        processAllProducers();
        console.log(`Producer added: ${producer.id}`);
      },
      producerRemoved: (producer: Producer) => {
        if (latestProducer && latestProducer.id === producer.id) {
          setLatestProducer(null);
        }
        processAllProducers();
        console.log(`Producer removed: ${producer.id}`);
      },
    };
    gstWebRTCAPI.registerProducersListener(producerListener);
  };

  useEffect(() => {
    listenForProducers();
    return () => {
      gstWebRTCAPI.unregisterAllProducersListeners();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default ProducerManager;

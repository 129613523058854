/* eslint-disable react/jsx-sort-props */
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import { Box, Button, Option, Select } from '@mui/joy';

export enum CameraSelection {
  multiSpectral,
  gimbal
}

export enum CameraSubSelection {
  ndvi,
  ndre
}

export type CameraControlsProps = {
  cameraSelection: CameraSelection,
  setCameraSelection: React.Dispatch<React.SetStateAction<CameraSelection>>,
  cameraSubSelection: CameraSubSelection,
  setCameraSubSelection: React.Dispatch<React.SetStateAction<CameraSubSelection>>,
  playing: boolean,
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>
}

export const CameraControls: React.FC<CameraControlsProps> = ({
  cameraSelection,
  setCameraSelection,
  cameraSubSelection,
  setCameraSubSelection,
  playing,
  setPlaying
}) => {
  const { t: translate } = useTranslation();

  return (
    <Box className='relative flex flex-row w-full'>
      <Button className='whitespace-nowrap'>
        {translate('campaign_view.camera_controls.assume_controls')}
      </Button>
      <Box sx={{ width: cameraSelection === CameraSelection.multiSpectral ? '145px' : '235px' }}>
        <Select
          defaultValue={CameraSelection.multiSpectral}
          onChange={(_, newValue) => setCameraSelection(newValue as CameraSelection)}
        >
          <Option value={CameraSelection.multiSpectral}>
            {translate('campaign_view.camera_controls.multi_spectral')}
          </Option>
          <Option value={CameraSelection.gimbal}>
            {translate('campaign_view.camera_controls.gimbal')}
          </Option>
        </Select>
      </Box>
      {cameraSelection === CameraSelection.multiSpectral &&
        <Select
          className='w-[90px]'
          value={cameraSubSelection}
          onChange={(_, newValue) => setCameraSubSelection(newValue as CameraSubSelection)}
        >
          <Option value={CameraSubSelection.ndvi}>
            {translate('campaign_view.camera_controls.ndvi')}
          </Option>
          <Option value={CameraSubSelection.ndre}>
            {translate('campaign_view.camera_controls.ndre')}
          </Option>
        </Select>
      }
      <Button className='w-[12%]' onClick={() => setPlaying(prev => !prev)}>
        {playing ? <PauseIcon /> : <PlayIcon />}
      </Button>
    </Box>
  );
}

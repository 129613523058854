import { MULTIPLE_USERS_REMOVE_ORGANIZATION } from '../constants/Users';
import User from '../models/User';
import { apiClient } from './ApiService';

const getUsers = async (): Promise<User[]> => {
  try {
    return await apiClient.get<User[]>('/account/users', {}, true);
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

const updateUser = (userId: number, userData: Partial<User>): Promise<User> => apiClient.put<User>(`/account/users/${userId}/`, userData, {}, true);

const changeUsernamePassword = (userId: number, old_password: string, password: string): Promise<void> => apiClient.put<void>(`/account/users/${userId}/change_password/`, { old_password, password }, {}, true);

const removeOrganization = async (userId: number): Promise<void> => {
  try {
    await apiClient.patch<void>(`/account/users/${userId}/remove_organization/`, {}, {}, true);
  } catch (error) {
    console.error('Error removing organization:', error);
    throw error;
  }
};

const bulkRemoveOrganization = async (userIds: string[], orgId: number): Promise<void> => {
  try {
    return await apiClient.delete(MULTIPLE_USERS_REMOVE_ORGANIZATION, {
      ids: userIds,
      org_id: orgId
    });
  } catch (error) {
    console.error('Error removing organization from users:', error);
    throw error;
  }
};

const sendResetPasswordEmail = (email: string): Promise<User> => apiClient.post<User>(`/account/request-reset-password/`, { email });

const resetPasswordRequest = (token: string, new_password: string): Promise<User> => apiClient.post<User>(`/account/reset-password/`, { token, new_password });

export const UserService = {
  getUsers,
  updateUser,
  changeUsernamePassword,
  removeOrganization,
  bulkRemoveOrganization,
  sendResetPasswordEmail,
  resetPasswordRequest
};

import { FC, PropsWithChildren, ReactNode } from 'react';

import { Box } from '@mui/joy';

interface PageBodyProps extends PropsWithChildren {
  header?: ReactNode;
}

export const PageBody: FC<PageBodyProps> = ({ header, children }) => (
    <Box>
      {header && <Box>{header}</Box>}
      {/* Main content */}
      <Box>{children}</Box>
    </Box>
  );

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/joy';

interface EmailSuccessProps {
  submittedEmail: string;
}

export const EmailSuccessMessage = ({ submittedEmail }: EmailSuccessProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography level="h1" sx={{ marginBottom: '1rem', fontWeight: '600' }}>
        {t('reset_password.reset_link_sent')}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'success.700',
          backgroundColor: 'success.100',
          padding: '1rem',
          borderRadius: '4px',
          marginBottom: '1rem'
        }}
      >
        {t('reset_password.email_sent')} {submittedEmail}.
      </Typography>
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: '500',
          color: 'neutral.600',
          marginBottom: '2rem',
          borderRadius: '4px'
        }}
      >
        {t('reset_password.check_spam')}
        <Typography sx={{ fontWeight: '700' }}>{t('reset_password.email_address')}.</Typography>
      </Typography>
      <Button
        color="neutral"
        onClick={() => navigate('/')}
        sx={{
          width: '100%',
          borderRadius: '4px',
          paddingY: '8px',
          paddingX: '24px',
          marginTop: '16px',
          fontSize: '1rem',
          lineHeight: '1rem',
          fontWeight: '400',
          minHeight: '3rem'
        }}
      >
        {t('reset_password.return_to_login')}
      </Button>
    </Box>
  );
};

import React, { ReactNode } from 'react';

import { Box, Grid } from '@mui/joy';

import { Navigation2 as Navigation } from './navigation/Navigation2';

interface TwoColumnLayoutProps {
  children: ReactNode;
}

const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({ children }) => (
  <Grid container height="100vh">
    {/* TODO add a max width */}
    <Grid md={2.5} sm={4} xs={12}>
      <Box boxShadow="lg" padding={3} sx={{ backgroundColor: 'background.surface', height: '100%' }}>
        <Navigation />
      </Box>
    </Grid>
    <Grid md={9.5} paddingLeft={4} paddingRight={4}
paddingTop={3} sm={8} xs={12}>
      <Box>{children}</Box>
    </Grid>
  </Grid>
);

export default TwoColumnLayout;

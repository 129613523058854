import React, { createContext, ReactNode, useContext, useState } from 'react';

interface OrganizationContextType {
  selectedOrg: number | null;
  setSelectedOrg: (id: number) => void;
}

const SelectedOrgContext = createContext<OrganizationContextType | undefined>(undefined);

export const useSelectedOrg = () => {
  const context = useContext(SelectedOrgContext);
  if (!context) {
    throw new Error('useSelectedOrg must be used within a SelectedOrgProvider');
  }
  return context;
};

export const SelectedOrgProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedOrg, setSelectedOrg] = useState<number | null>(null);

  return <SelectedOrgContext.Provider value={{ selectedOrg, setSelectedOrg }}>{children}</SelectedOrgContext.Provider>;
};

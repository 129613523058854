import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiLogout } from 'react-icons/ci';
import { PiArrowRightThin , PiPlusSquareThin } from 'react-icons/pi';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useTheme } from '@mui/joy';

import { InvitationService } from '../services/InvitationService';

export default function OrganizationList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [organizationName, setOrganizationName] = useState('');
  const [token, setToken] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenFromUrl = params.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }

    const fetchOrganizationName = async () => {
      const orgName = params.get('org');
      setOrganizationName(orgName || 'SIFLY');
    };

    fetchOrganizationName();
  }, [location.search]);

  const handleAcceptInvitation = async () => {
    try {
      await InvitationService.acceptOrgInvitation(token);
      navigate('/dashboard');
    } catch (error) {
      toast.error(t('organization_list.errors.invitation_not_accepted'));
    }
  };

  return (
    <div className="min-h-screen flex flex-col" style={{ background: theme.palette.neutral[100] }}>
      <div className="border-b border-opacity-30" style={{ color: theme.palette.neutral[500] }}>
        <img alt="Sifly Logo" className="h-5 w-28 font-medium m-5" src="images/logos/siflyBlackLogo.png" />
      </div>

      <div className="flex items-center justify-center min-h-[75vh]">
        <div className="max-w-[29rem]">
          <h1
            className="text-4xl leading-[3rem] font-semibold mb-8"
            style={{ color: theme.palette.neutral[800], fontFamily: theme.fontFamily.display }}
          >
            {t('organization.access_message')}
          </h1>

          <div
            className="shadow-md rounded-lg overflow-hidden w-full max-w-md"
            style={{ background: theme.palette.neutral[50] }}
          >
            <div className="flex items-center justify-between px-3 py-4 border-b border-gray-200">
              <div className="flex items-center space-x-4">
                <img alt="Organization Logo" className="h-8 w-8" src="images/logos/OrganizationLogo.png" />
                <span className="text-lg font-medium text-black">{organizationName}</span>
              </div>
              <button
                className="cursor-pointer flex items-center space-x-2 border py-3 px-6 rounded-md hover:bg-gray-300 transition duration-200"
                onClick={handleAcceptInvitation}
                type='button'
                style={{
                  color: theme.palette.neutral[700],
                  borderColor: theme.palette.neutral[300]
                }}
              >
                <span>{t('organization_list.join')}</span>
                <PiArrowRightThin size={24} style={{ color: theme.palette.neutral[500] }} />
              </button>
            </div>

            <div className="py-1">
              <button
                className="cursor-pointer flex items-center w-full px-3 py-2"
                type='button'
                onClick={() => navigate('/create-organization')}
              >
                <PiPlusSquareThin className="mr-3" size={20} style={{ color: theme.palette.neutral[500] }} />
                <span className="text-base font-normal" style={{ color: theme.palette.neutral[700] }}>
                  {t('organization_list.create_organization')}
                </span>
              </button>

              <button className="cursor-pointer flex items-center w-full px-3 py-2" type='button' onClick={() => navigate('/logout')}>
                <CiLogout
                  className="transform -scale-x-100 mr-3"
                  size={20}
                  style={{ color: theme.palette.neutral[500] }}
                />
                <span className="text-base font-normal" style={{ color: theme.palette.neutral[700] }}>
                  {t('organization_list.log_out')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

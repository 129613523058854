import * as Yup from 'yup';

const newPasswordSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('signup_page.errors.required'))
      .min(12, t('signup_page.errors.password_length'))
      .matches(/[!@#$%^&*]/, t('signup_page.errors.special_character')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('signup_page.errors.password_mismatch'))
      .required(t('signup_page.errors.required'))
  });

export default newPasswordSchema;

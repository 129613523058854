import * as React from 'react';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

interface TableHeaderProps {
  title: string;
  children?: React.ReactNode;
}

const TableHeader: React.FC<TableHeaderProps> = ({ title, children }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}
    >
      <Typography level="h4">{title}</Typography>
      {children}
    </Box>
  );

export default TableHeader;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate , useSearchParams } from 'react-router-dom';

import { Box, Typography } from '@mui/joy';

import { SimpleHeaderLayout } from '../../components/layout/SimpleHeaderLayout';
import { EmailResetForm } from './EmailResetForm';
import { EmailSuccessMessage } from './EmailSuccessMessage';
import { PasswordResetForm } from './PasswordResetForm';
import { PasswordSuccessMessage } from './PasswordSuccessMessage';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('eid');

  useEffect(() => {
    setIsResettingPassword(!!token);
  }, [token]);

  const cancelButton = (
    <Typography onClick={() => navigate('/')} sx={{ cursor: 'pointer', alignSelf: 'center', margin: '1rem' }}>
      {t('edit_profile.cancel')}
    </Typography>
  );

  return (
    <SimpleHeaderLayout controlComponent={cancelButton}>
      <Box className="flex items-center justify-center min-h-[75vh] m-4">
        <Box className="w-[29rem]">
          {!isResettingPassword && !emailSent && !passwordResetSuccess && (
            <>
              <Typography level="h1" sx={{ marginBottom: '2rem', fontWeight: '600' }}>
                {t('reset_password.heading')}
              </Typography>
              <EmailResetForm setEmailSent={setEmailSent} setSubmittedEmail={setSubmittedEmail} />
              <Typography
                onClick={() => navigate('/')}
                sx={{
                  textAlign: 'center',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginTop: '1rem'
                }}
              >
                {t('reset_password.return_to_login')}
              </Typography>
            </>
          )}

          {emailSent && !isResettingPassword && !passwordResetSuccess && (
            <EmailSuccessMessage submittedEmail={submittedEmail} />
          )}

          {isResettingPassword && !passwordResetSuccess && (
            <>
              <Typography level="h1" sx={{ marginBottom: '2rem', fontWeight: '600' }}>
                {t('reset_password.heading')}
              </Typography>
              <PasswordResetForm setPasswordResetSuccess={setPasswordResetSuccess} token={token ?? ''} />
            </>
          )}

          {passwordResetSuccess && <PasswordSuccessMessage />}
        </Box>
      </Box>
    </SimpleHeaderLayout>
  );
};

import { ReactNode } from 'react';

import { Box } from '@mui/joy';

const CampaignDashboardLayout: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    className='w-full h-screen max-h-screen p-4'
  >
    <Box className='w-full h-full'>
      {children}
    </Box>
  </Box>
);

export default CampaignDashboardLayout;

import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Container } from '@mui/joy';

import DroneModelConfigForm from '../../components/DroneModels/configuration-edit/DroneModelConfigForm';
import DroneModelDetailsForm from '../../components/DroneModels/details-edit/DroneModelDetailsForm';
import { getDroneModelAllFormSchema } from '../../components/DroneModels/droneModelFormSchema';
import BreadcrumbLayout from '../../components/layout/BreadcrumbLayout';
import ResponsiveLayout from '../../components/layout/ResponsiveLayout';
import { useApiService } from '../../hooks/useAPIService';
import { BreadcrumbItem } from '../../models/BreadcrumbItem';
import { FullDroneModelInput } from '../../models/DroneModel/DroneModel.model';
import { DroneModelService } from '../../services/DroneModelService';
import { ValidationException } from '../../services/Exceptions';
import { serializeDroneModelCreateToBackend } from '../../services/serializers/DroneModelSerializer';

const AddDroneModelPage: FC = () => {
  const { t } = useTranslation();

  const [breadcrumbItems] = useState<BreadcrumbItem[]>([
    { text: t('drone_models.title'), link: '/drone-models' },
    { text: t('drone_models.create_page.create_new_drone_model') }
  ]);
  const navigate = useNavigate();

  const { loading, callService } = useApiService(t);

  const yupSchema = getDroneModelAllFormSchema(t);
  const methods = useForm<FullDroneModelInput>({
    defaultValues: {
      name: '',
      manufacturer: '',
      type: '',
      description: '',
      configuration: ''
    },
    resolver: yupResolver(yupSchema)
  });

  const { handleSubmit } = methods;

  const onSubmit = async (droneModel: FullDroneModelInput) => {
    const onValidationError = (error: ValidationException) => {
      console.error('Validation error:', error); // TODO: Remove this line and handle the error properly, when backend is done.
    };

    try {
      const serializedData = serializeDroneModelCreateToBackend(droneModel);
      const response = await callService({
        serviceFunction: DroneModelService.createDroneModel,
        args: serializedData,
        onValidationError
      });

      if (response.success && response.data) {
        toast.success(t('drone_models.create_page.create_drone_model_success'));
        navigate('/drone-models');
      } else {
        toast.error(t('drone_models.create_page.create_drone_model_error'));
        console.error('Error creating drone model:', response.error);
      }
    } catch (error) {
      toast.error(t('drone_models.create_page.create_drone_model_error'));
      console.error('Error creating drone model:', error);
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', width: '100%', border: 'solid 1px' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <ResponsiveLayout>
      <BreadcrumbLayout breadcrumbItems={breadcrumbItems}>
        <Container>
          <FormProvider {...methods}>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ flex: 1, minWidth: '48%', minHeight: '300px' }}
            >
              <DroneModelDetailsForm />
              <DroneModelConfigForm />
              <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
                <Button color="neutral" type="submit" variant="solid">
                  {t('general_button_labels.save_changes')}
                </Button>
                <Button
                  color="neutral"
                  onClick={() => {
                    navigate('/drone-models');
                  }}
                  variant="outlined"
                >
                  {t('general_button_labels.cancel')}
                </Button>
              </Box>
            </Box>
          </FormProvider>
        </Container>
      </BreadcrumbLayout>
    </ResponsiveLayout>
  );
};

export default AddDroneModelPage;

import { makeAutoObservable } from 'mobx';

import { Organization } from '../models/Company';

class OrganizationStore {
  organizations: Organization[] = [];

  selectedOrg: number | null = null;

  constructor() {
    makeAutoObservable(this);
    this.loadSelectedOrg();
  }

  setOrganizations(organizations: Organization[]) {
    this.organizations = organizations;
  }

  setSelectedOrg(orgId: number) {
    this.selectedOrg = orgId;
    this.persistSelectedOrg();
  }

  persistSelectedOrg() {
    if (this.selectedOrg !== null) {
      localStorage.setItem('selectedOrg', this.selectedOrg.toString());
    } else {
      localStorage.removeItem('selectedOrg');
    }
  }

  loadSelectedOrg() {
    const storedOrgId = localStorage.getItem('selectedOrg');
    if (storedOrgId) {
      this.selectedOrg = Number(storedOrgId);
    }
  }

  clearSelectedOrg() {
    this.selectedOrg = null;
    localStorage.removeItem('selectedOrg');
  }
}

export const organizationStore = new OrganizationStore();

import * as Yup from 'yup';

const loginFormSchema = (t: (key: string) => string) =>
  Yup.object().shape({
    email: Yup.string().email(t('signup_page.errors.email_format')).required(t('signup_page.errors.required')),

    password: Yup.string().required(t('signup_page.errors.required'))
  });

export default loginFormSchema;

export const formatTimestamp = (
  timestamp: string | null,
  userLocale: string,
  options: {
    includeTimezone?: boolean,
    onlyHourAndMinute?: boolean
  } = {
      includeTimezone: false,
      onlyHourAndMinute: false
    }
): string => {
  if (!timestamp) {
    return 'Not Available'; // Fallback for missing dates
  }

  const date = new Date(timestamp);
  const formattedDate = date.toLocaleString(userLocale, options.onlyHourAndMinute
    ? {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // 12-hour format for better readability
    }
    : {
      year: 'numeric',
      month: 'long', // Full month name for clarity
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // 12-hour format for better readability
    });

  if (options.includeTimezone) {
    const timezoneName = Intl.DateTimeFormat('en-US', {
      timeZoneName: 'short'
    }).formatToParts(date);

    const timeZonePart = timezoneName.find(part => part.type === 'timeZoneName');
    const timezone = timeZonePart ? timeZonePart.value : '';

    return `${formattedDate} (${timezone})`; // Append timezone info
  }

  return formattedDate;
};

/* eslint-disable prefer-template */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-console */
import { ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/joy';

import ImageDisplay from '../ImageDisplay';
import MapCardView from '../MapDroneView';
import VideoDisplay from '../VideoDisplay';
import { CameraControls, CameraSelection, CameraSubSelection } from './CameraControls';
import { BigPictureDisplayType } from './CampaignHeader';

type ViewportProps = {
  displayPictureInPicture: boolean,
  bigPictureDisplayType: BigPictureDisplayType
}

const Viewport: React.FC<ViewportProps> = ({
  displayPictureInPicture,
  bigPictureDisplayType
}) => {
  const [cameraSelection, setCameraSelection] = useState<CameraSelection>(CameraSelection.multiSpectral);
  const [cameraSubSelection, setCameraSubSelection] = useState<CameraSubSelection>(CameraSubSelection.ndvi);
  const [playing, setPlaying] = useState<boolean>(true);

  useEffect(() => {
    // TODO: send camera sub selection to backend
  }, [cameraSubSelection]);

  const getRasterDisplay = (): ReactNode => cameraSelection === CameraSelection.multiSpectral
    ? <ImageDisplay playing={playing} />
    : <VideoDisplay />;

  const bigPictureClass = 'relavite flex w-full h-full items-center justify-center';
  const smallPictureClass = 'absolute flex bottom-20 left-4 w-[420px] h-1/5 items-center justify-center';

  return (
    <Box className='relative flex flex-1 flex-col h-[calc(100%-48px)] max-h-[calc(100%-48px)]'>
      {
        bigPictureDisplayType === BigPictureDisplayType.LIVE_ACTIVITY
          ? <>
            <Box className={`${bigPictureClass} border-4 border-yellow-500`}>
              {getRasterDisplay()}
            </Box>
            {displayPictureInPicture && <Box className={`${smallPictureClass} border-4 border-red-500 z-1000`}>
              <MapCardView />
            </Box>}
          </>
          : <>
            <Box className={`${bigPictureClass} border-4 border-yellow-500`}>
            <MapCardView />
            </Box>
            {displayPictureInPicture && <Box className={`${smallPictureClass} border-4 border-red-500 z-1000`}>
              {getRasterDisplay()}
            </Box>}
          </>
      }
      <Box className='absolute flex bottom-4 left-4 w-[420px] h-1/20 items-center justify-center'>
        <CameraControls
          cameraSelection={cameraSelection}
          setCameraSelection={setCameraSelection}
          cameraSubSelection={cameraSubSelection}
          setCameraSubSelection={setCameraSubSelection}
          playing={playing}
          setPlaying={setPlaying}
        />
      </Box>
    </Box >
  )
}

export default Viewport;
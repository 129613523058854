import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/joy';

import { TextInputField } from '../../components/TextInputField';
import newPasswordSchema from '../../schema/passwordResetSchema';
import { UserService } from '../../services/UserService';

interface NewPasswordForm {
  password: string;
  confirmPassword: string;
}

interface PasswordResetFormProps {
  token: string;
  setPasswordResetSuccess: (success: boolean) => void;
}

export const PasswordResetForm = ({ token, setPasswordResetSuccess }: PasswordResetFormProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<NewPasswordForm>({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(newPasswordSchema(t))
  });

  const onPasswordSubmit: SubmitHandler<NewPasswordForm> = async (data) => {
    try {
      await UserService.resetPasswordRequest(token, data.password);
      setPasswordResetSuccess(true);
      toast.success(t('reset_password.success.password_updated'));
    } catch (error) {
      toast.error(t('reset_password.errors.failed_to_reset'));
    }
  };

  return (
    <form onSubmit={handleSubmit(onPasswordSubmit)}>
      <Box>
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextInputField
              label={t('edit_profile.new_password')}
              type="password"
              {...field}
              error={errors.password ? errors.password.message : ''}
              sx={{
                width: '100%',
                paddingX: '16px',
                paddingY: '12px',
                minHeight: '48px',
                fontSize: '14px',
                borderWidth: '1px',
                borderRadius: '6px'
              }}
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <TextInputField
              label={t('signup_page.confirm_password')}
              type="password"
              {...field}
              error={errors.confirmPassword ? errors.confirmPassword.message : ''}
              sx={{
                width: '100%',
                paddingX: '16px',
                paddingY: '12px',
                minHeight: '48px',
                fontSize: '14px',
                borderWidth: '1px',
                borderRadius: '6px'
              }}
            />
          )}
        />
      </Box>
      <Button
        color="neutral"
        sx={{
          width: '100%',
          borderRadius: '4px',
          paddingY: '8px',
          paddingX: '24px',
          marginTop: '16px',
          fontSize: '1rem',
          lineHeight: '1rem',
          fontWeight: '400',
          minHeight: '3rem'
        }}
        type="submit"
      >
        {t('reset_password.button')}
      </Button>
    </form>
  );
};

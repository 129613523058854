/* eslint-disable react/jsx-sort-props */
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/joy';

import { ReactComponent as SiflyLogo } from '../../assets/images/SiflyLogo.svg';
import ContrastButton from '../ContrastButton';

export enum BigPictureDisplayType {
  LIVE_ACTIVITY,
  MAP
}

type CampaignHeaderProps = {
  projectName: string,
  campaignName: string,
  bigPictureDisplayType: BigPictureDisplayType,
  setBigPictureDisplayType: Dispatch<SetStateAction<BigPictureDisplayType>>
}

const CampaignHeader: React.FC<CampaignHeaderProps> = ({
  projectName,
  campaignName,
  bigPictureDisplayType,
  setBigPictureDisplayType
}) => {
  const { t: translate } = useTranslation();

  return (
    <Box className='flex w-full h-12 items-center justify-start gap-6 px-3'>
      <SiflyLogo />
      <Box
        sx={{
          color: 'text.secondary'
        }}
      >
        {`${projectName} / ${campaignName}`}
      </Box>
      {
        bigPictureDisplayType === BigPictureDisplayType.LIVE_ACTIVITY
          ? <>
            <ContrastButton>
              {translate('campaign_view.live_activity')}
            </ContrastButton>
            <Box className='px-4 cursor-pointer'
              onClick={() => setBigPictureDisplayType(BigPictureDisplayType.MAP)}
            >
              {translate('campaign_view.map')}
            </Box>
          </>
          : <>
            <Box className='px-4 cursor-pointer'
              onClick={() => setBigPictureDisplayType(BigPictureDisplayType.LIVE_ACTIVITY)}
            >
              {translate('campaign_view.live_activity')}
            </Box>
            <ContrastButton>
              {translate('campaign_view.map')}
            </ContrastButton>
          </>
      }
    </Box>
  )
}

export default CampaignHeader;
import React, { useEffect, useState } from 'react';

interface VersionInfoState {
  version: string;
  buildTime: string;
  imageTag: string;
  environment: string;
}

const VersionInfo: React.FC = () => {
  const [versionInfo, setVersionInfo] = useState<VersionInfoState | null>(null);

  useEffect(() => {
    fetch('/version.json')
      .then((response) => response.json())
      .then((data) => setVersionInfo(data))
      .catch((error) => console.error('Error loading version info:', error));
  }, []);

  if (!versionInfo) {
    return <div>Loading version info...</div>;
  }

  return (
    <div className='w-full max-w-2xl mx-auto p-4 bg-white shadow-md rounded-lg mt-10'>
      <header className='mb-4'>
        <h1 className='text-2xl font-bold text-gray-800'>Version Information</h1>
      </header>
      <div className='space-y-2'>
        <div className='flex justify-between'>
          <span className='font-medium text-gray-600'>Version:</span>
          <span className='text-gray-800'>{versionInfo.version}</span>
        </div>
        <div className='flex justify-between'>
          <span className='font-medium text-gray-600'>Image Tag:</span>
          <span className='text-gray-800'>{versionInfo.imageTag}</span>
        </div>
        <div className='flex justify-between'>
          <span className='font-medium text-gray-600'>Build Date:</span>
          <span className='text-gray-800'>{new Date(versionInfo.buildTime).toLocaleString()}</span>
        </div>
        <div className='flex justify-between'>
          <span className='font-medium text-gray-600'>Environment:</span>
          <span className='text-gray-800'>{versionInfo.environment}</span>
        </div>
      </div>
    </div>
  );
};

export default VersionInfo;

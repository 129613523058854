import { extendTheme } from '@mui/joy';

const siflyThemeTokens = {
  colorSchemes: {
    light: {
      palette: {
        divider: 'rgba(99, 107, 116, 0.3000)',
        focusVisible: 'var(--joy-palette-text-primary, #NANNANNAN)',
        background: {
          backdrop: 'rgba(18, 20, 22, 0.2500)',
          body: 'var(--joy-palette-common-white, #FFFFFF)',
          popup: 'var(--joy-palette-common-white, #FFFFFF)',
          surface: 'var(--joy-palette-neutral-050, #FBFCFE)'
        },
        primary: {
          '50': '#FEFDE6',
          '100': '#FCFAC0',
          '200': '#FAF696',
          '300': '#F7F26A',
          '400': '#F4ED43',
          '500': '#F1E90A',
          '600': '#F5D907',
          '700': '#F5C100',
          '800': '#F6A800',
          '900': '#F57D00',
          darkChannel: 'rgba(245, 125, 0, 0.6000)',
          lightChannel: 'rgba(250, 246, 150, 0.6000)',
          mainChannel: 'rgba(245, 217, 7, 0.6000)',
          solidActiveBg: 'var(--joy-palette-primary-700, #F5C100)',
          solidBg: 'var(--joy-palette-primary-500, #F1E90A)',
          solidColor: 'var(--joy-palette-common-black, #000000)',
          solidHoverBg: 'var(--joy-palette-primary-600, #F5D907)',
          outlinedActiveBg: 'var(--joy-palette-primary-200, #FAF696)',
          outlinedBorder: 'var(--joy-palette-primary-300, #F7F26A)',
          outlinedColor: 'var(--joy-palette-common-black, #000000)',
          outlinedHoverBg: 'var(--joy-palette-primary-100, #FCFAC0)',
          plainActiveBg: 'var(--joy-palette-primary-200, #FAF696)',
          plainColor: 'var(--joy-palette-common-black, #000000)',
          plainHoverBg: 'var(--joy-palette-primary-100, #FCFAC0)',
          softActiveBg: 'var(--joy-palette-primary-300, #F7F26A)',
          softActiveColor: 'var(--joy-palette-primary-800, #F6A800)',
          softBg: 'var(--joy-palette-primary-100, #FCFAC0)',
          softColor: 'var(--joy-palette-primary-700, #F5C100)',
          softDisabledBg: 'var(--joy-palette-neutral-050, #FBFCFE)',
          softHoverBg: 'var(--joy-palette-primary-200, #FAF696)'
        },
        neutral: {
          '50': '#FBFCFE',
          '100': '#F0F4F8',
          '200': '#DDE7EE',
          '300': '#CDD7E1',
          '400': '#9FA6AD',
          '500': '#636B74',
          '600': '#555E68',
          '700': '#32383E',
          '800': '#171A1C',
          '900': '#0B0D0E',
          darkChannel: 'rgba(50, 56, 62, 0.6000)',
          lightChannel: 'rgba(221, 231, 238, 0.6000)',
          mainChannel: 'rgba(99, 107, 116, 0.6000)',
          solidBg: 'var(--joy-palette-neutral-800, #171A1C)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)',
          softDisabledBg: 'var(--joy-palette-neutral-050, #FBFCFE)'
        },
        success: {
          '50': '#F6FEF6',
          '100': '#E3FBE3',
          '200': '#C7F7C7',
          '300': '#A1E8A1',
          '400': '#51BC51',
          '500': '#1F7A1F',
          '600': '#136C13',
          '700': '#0A470A',
          '800': '#042F04',
          '900': '#021D02',
          darkChannel: 'rgba(10, 71, 10, 0.6000)',
          lightChannel: 'rgba(199, 247, 199, 0.6000)',
          mainChannel: 'rgba(31, 122, 31, 0.6000)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)',
          softDisabledBg: 'var(--joy-palette-neutral-050, #FBFCFE)'
        },
        danger: {
          '50': '#FEF6F6',
          '100': '#FCE4E4',
          '200': '#F7C5C5',
          '300': '#F09898',
          '400': '#E47474',
          '500': '#C41C1C',
          '600': '#A51818',
          '700': '#7D1212',
          '800': '#430A0A',
          '900': '#240505',
          darkChannel: 'rgba(125, 18, 18, 0.6000)',
          lightChannel: 'rgba(247, 197, 197, 0.6000)',
          mainChannel: 'rgba(196, 28, 28, 0.6000)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)',
          softDisabledBg: 'var(--joy-palette-neutral-050, #FBFCFE)'
        },
        warning: {
          '50': '#FEFAF6',
          '100': '#FDF0E1',
          '200': '#FCE1C2',
          '300': '#F3C896',
          '400': '#EA9A3E',
          '500': '#9A5B13',
          '600': '#72430D',
          '700': '#492B08',
          '800': '#2E1B05',
          '900': '#1D1002',
          darkChannel: 'rgba(73, 43, 8, 0.6000)',
          lightChannel: 'rgba(252, 225, 194, 0.6000)',
          mainChannel: 'rgba(154, 91, 19, 0.6000)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)',
          softDisabledBg: 'var(--joy-palette-neutral-050, #FBFCFE)'
        },
        common: {
          white: '#FFFFFF',
          black: '#000000'
        }
      }
    },
    dark: {
      palette: {
        divider: 'rgba(99, 107, 116, 0.1600)',
        focusVisible: 'var(--joy-palette-text-primary, #NANNANNAN)',
        background: {
          backdrop: 'rgba(245, 247, 250, 0.2500)',
          body: 'var(--joy-palette-common-black, #000000)',
          popup: 'var(--joy-palette-common-black, #000000)'
        },
        primary: {
          '50': '#FEFDE6',
          '100': '#FCFAC0',
          '200': '#FAF696',
          '300': '#F7F26A',
          '400': '#F4ED43',
          '500': '#F1E90A',
          '600': '#F5D907',
          '700': '#F5C100',
          '800': '#F6A800',
          '900': '#F57D00',
          darkChannel: 'rgba(245, 125, 0, 0.6000)',
          lightChannel: 'rgba(250, 246, 150, 0.6000)',
          mainChannel: 'rgba(245, 217, 7, 0.6000)',
          solidActiveBg: 'var(--joy-palette-primary-700, #F5C100)',
          solidBg: 'var(--joy-palette-primary-500, #F1E90A)',
          solidColor: 'var(--joy-palette-common-black, #000000)',
          solidHoverBg: 'var(--joy-palette-primary-600, #F5D907)',
          outlinedActiveBg: 'var(--joy-palette-primary-700, #F5C100)',
          outlinedBorder: 'var(--joy-palette-primary-700, #F5C100)',
          outlinedColor: 'var(--joy-palette-primary-200, #FAF696)',
          outlinedHoverBg: 'var(--joy-palette-primary-800, #F6A800)',
          plainActiveBg: 'var(--joy-palette-primary-700, #F5C100)',
          plainColor: 'var(--joy-palette-primary-300, #F7F26A)',
          plainHoverBg: 'var(--joy-palette-primary-800, #F6A800)',
          softActiveBg: 'var(--joy-palette-primary-600, #F5D907)',
          softActiveColor: 'var(--joy-palette-primary-100, #FCFAC0)',
          softBg: 'var(--joy-palette-primary-800, #F6A800)',
          softColor: 'var(--joy-palette-primary-200, #FAF696)',
          softHoverBg: 'var(--joy-palette-primary-700, #F5C100)'
        },
        neutral: {
          '50': '#FBFCFE',
          '100': '#F0F4F8',
          '200': '#DDE7EE',
          '300': '#CDD7E1',
          '400': '#9FA6AD',
          '500': '#636B74',
          '600': '#555E68',
          '700': '#32383E',
          '800': '#171A1C',
          '900': '#0B0D0E',
          darkChannel: 'rgba(50, 56, 62, 0.6000)',
          lightChannel: 'rgba(221, 231, 238, 0.6000)',
          mainChannel: 'rgba(99, 107, 116, 0.6000)',
          solidBg: 'var(--joy-palette-neutral-100, #F0F4F8)',
          solidColor: 'var(--joy-palette-common-black, #000000)',
          outlinedDisabledColor: '#636B74'
        },
        success: {
          '50': '#F6FEF6',
          '100': '#E3FBE3',
          '200': '#C7F7C7',
          '300': '#A1E8A1',
          '400': '#51BC51',
          '500': '#1F7A1F',
          '600': '#136C13',
          '700': '#0A470A',
          '800': '#042F04',
          '900': '#021D02',
          darkChannel: 'rgba(10, 71, 10, 0.6000)',
          lightChannel: 'rgba(199, 247, 199, 0.6000)',
          mainChannel: 'rgba(31, 122, 31, 0.6000)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)'
        },
        danger: {
          '50': '#FEF6F6',
          '100': '#FCE4E4',
          '200': '#F7C5C5',
          '300': '#F09898',
          '400': '#E47474',
          '500': '#C41C1C',
          '600': '#A51818',
          '700': '#7D1212',
          '800': '#430A0A',
          '900': '#240505',
          darkChannel: 'rgba(125, 18, 18, 0.6000)',
          lightChannel: 'rgba(247, 197, 197, 0.6000)',
          mainChannel: 'rgba(196, 28, 28, 0.6000)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)'
        },
        warning: {
          '50': '#FEFAF6',
          '100': '#FDF0E1',
          '200': '#FCE1C2',
          '300': '#F3C896',
          '400': '#EA9A3E',
          '500': '#9A5B13',
          '600': '#72430D',
          '700': '#492B08',
          '800': '#2E1B05',
          '900': '#1D1002',
          darkChannel: 'rgba(73, 43, 8, 0.6000)',
          lightChannel: 'rgba(252, 225, 194, 0.6000)',
          mainChannel: 'rgba(154, 91, 19, 0.6000)',
          solidColor: 'var(--joy-palette-common-white, #FFFFFF)'
        },
        common: {
          white: '#FFFFFF',
          black: '#000000'
        }
      }
    }
  },
  fontFamily: {
    display: '"Be Vietnam Pro", sans-serif', // Font for headings and display text
    body: '"Barlow", sans-serif' // Global default font
  }
};

export default extendTheme(siflyThemeTokens);
